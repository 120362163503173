import * as React from 'react';
import './AvailabilitySubscribe.scss';
import '../../rapidmail.scss';
import useScript from '../../modules/helper/useScript';
import type {GoogleAnalyticsContextProps} from '../../modules/tracking/GoogleAnalyticsContext';
import {useContext, useState} from 'react';
import GoogleAnalyticsContext from '../../modules/tracking/GoogleAnalyticsContext';

export default function AvailabilitySubscribe() {
  useScript('https://t08dd98dc.emailsys1a.net/form/126/2333/a4fa881cfd/embedded.js');

  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  const [email, setEmail] = useState('');
  
  function onClick() {
    if (email.length >= 5) {
      googleAnalytics.event('generate_lead', {
        ...googleAnalytics.identifierParams(
            'shop', 'availability_subscribe'
        )
      });
    }
  }

  return (
      <div className="AvailabilitySubscribe">
        <div id="rmOrganism">
          <div className="rmEmbed rmLayout--vertical rmBase">
            <div data-page-type="formSubscribe" className="rmBase__body rmSubscription">
              <form method="post" action="https://t08dd98dc.emailsys1a.net/126/2767/e17cd93b32/subscribe/form.html?_g=1604312445"
                    className="rmBase__content">
                <div className="rmBase__container">
                  <div className="rmBase__section">
                    <div className="rmBase__el rmBase__el--input rmBase__el--label-pos-left" data-field="email">
                      <label htmlFor="email" className="rmBase__compLabel rmBase__compLabel--hideable">
                        E-Mail
                      </label>
                      <div className="rmBase__compContainer">
                        <input onChange={event => setEmail(event.target.value)} type="text" name="email" id="email" placeholder="max@techatom.de" className="rmBase__comp--input comp__input"/>
                        <div className="rmBase__compError"/>
                      </div>
                    </div>
                    <div className="rmBase__el rmBase__el--cta">
                      <button onClick={onClick} type="submit" className="rmBase__comp--cta">
                        Bei Verfügbarkeit benachrichtigen
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div data-page-type="pageSubscribeSuccess" className="rmBase__body rmSubscription hidden">
              <div className="rmBase__content">
                <div className="rmBase__container">
                  <div className="rmBase__section">
                    <div className="rmBase__el rmBase__el--text">
                      <div className="rmBase__comp--text">
                        Vielen Dank für Deine Anmeldung!
                        <br/>
                        <br/>
                        Eine E-Mail mit einem Aktivierungslink wurde an Deine E-Mail-Adresse geschickt - bitte prüfe auch Deinen SPAM-Ordner.
                        <br/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}