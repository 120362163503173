import * as React from 'react';
import {AtomFrame, AtomImage} from './Atom';
import styles from './Opfunc2in1out.module.scss';
import type {AtomProps} from './Atom';

type Props = {
  ...AtomProps,
  symbolImage: ?string
}

export default function Opfunc2in1out(props: Props) {
  return (
      <AtomFrame
          {...props} rotation={(props.rotation || 0) + 60}
          className={styles.Opfunc2in1out}
      >
        {props.symbolImage &&
          <AtomImage 
               style={{backgroundImage: 'url(' + props.symbolImage + ')'}}
          />
        }
      </AtomFrame>
  )
};