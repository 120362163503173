import * as React from 'react';
import DataPrivacyContext from '../dataprivacy/DataPrivacyContext';
import type {DataPrivacyContextProps} from '../dataprivacy/DataPrivacyContext';

const GoogleAnalyticsContext = React.createContext();

const privacyServiceKey = 'googleAnalytics';

window.dataLayer = window.dataLayer || [];
window.gtag = window.gtag || function() {
  window.dataLayer.push(arguments);
};
window.gtag('js', new Date());

type State = {
  debugLogging: boolean
}

type Props = {
  gaMeasurementId: string
}

export type GoogleAnalyticsContextProps = {
  isDebugLogging: boolean,
  //enableDebugLogging: (enabled) => void,

  event: (name: string, args: Object, skipDefaultParams?: boolean) => void,
  defaultEventParams: () => { hash_location: string, scrolled: number, percent_scrolled: number },
  identifierParams: (section: string, key?: string, label?: string) => { section: string, key?: string, label?: string, full_id: string },
}

export const GoogleAnalyticsConsumer = GoogleAnalyticsContext.Consumer;

export class GoogleAnalyticsProvider extends React.Component {
  // Context state
  state: State;
  props: Props;
  context: DataPrivacyContextProps;
  static contextType = DataPrivacyContext;

  constructor() {
    super();
    //this.acceptAll = this.acceptAll.bind(this);
    this.state = {
      debugLogging: false
    };
    this.defaultEventParams = this.defaultEventParams.bind(this);
    this.identifierParams = this.identifierParams.bind(this);
    this.event = this.event.bind(this);
  }

  componentWillMount() {
    // load debug settings
    const gaDebug = localStorage.getItem('gaDebug');
    if (!!gaDebug) {
      this.setState({debugLogging: true});
      console.log('GA debug log enabled');
    }

    if (this.props.gaMeasurementId) {
      window.gtag('config', this.props.gaMeasurementId);
    }

    this.context.registerService({
      key: privacyServiceKey,
      name: 'Google Analytics',
      category: 'tracking'
    }, () => {
      if (this.props.gaMeasurementId) {
        this.context.onServiceEnabled(privacyServiceKey, () => {
          const script = document.createElement('script');
          script.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.props.gaMeasurementId;
          script.async = true;
          document.body.appendChild(script);
        });
      }
    });
  }

  defaultEventParams() {
    const scrolled = window.scrollY;
    const percent_scrolled = window.scrollY / (document.body.clientHeight - window.innerHeight);

    return {
      hash_location: document.location.pathname + document.location.hash,
      scrolled,
      percent_scrolled
    };
  }

  identifierParams(section: string, key?: string, label?: string) {
    return {
      section,
      key,
      label,
      full_id: section
               + (key ? ' ' + key : '')
               + (label ? ' ' + label : '')
    };
  }

  event(name, args, skipDefaultParams) {
    const isDebugLogging = this.state.debugLogging;
    if (!skipDefaultParams) {
      args = {...this.defaultEventParams(), ...args};
    }
    if (isDebugLogging) {
      console.debug('ga event', name, args);
    }
    this.context.guardService(privacyServiceKey, () => {
      window.gtag('event', name, args);
    });
  }

  render() {
    const {children} = this.props;
    const isDebugLogging = this.state.debugLogging;

    const contextValue: GoogleAnalyticsContextProps = {
      defaultEventParams: this.defaultEventParams,
      identifierParams: this.identifierParams,
      event: this.event,
      isDebugLogging
    };

    return (
        <GoogleAnalyticsContext.Provider
            value={contextValue}
        >
          {children}
        </GoogleAnalyticsContext.Provider>
    );
  }
}

export default GoogleAnalyticsContext;
