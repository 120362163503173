import * as React from 'react';
import KeyboardAtom from './atoms/KeyboardAtom';
import styles from './InteractiveAssembly.module.scss';
import Operator2in1outAtom from './atoms/Operator2in1outAtom';
import {useContext, useEffect, useRef, useState} from 'react';
import ParallaxHelper from '../../../modules/helper/ParallaxHelper';
import LedRingAtom from './atoms/LedRingAtom';
import SimpleValueInputAtom from './atoms/SimpleValueInputAtom';
import PowerAtom from './atoms/PowerAtom';
import {useIsPhone} from '../../../TechAtomHooks';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import BooleanArray from './values/BooleanArray';
import SingleNumber from './values/SingleNumber';
import Opfunc2in1out from './atoms/Opfunc2in1out';
import Opfunc2in1outImg_BrightnessHue from './atoms/images/opfunc-2in1out_brightnessHue.png';
import Opfunc2in1outImg_NumberConstant100 from './atoms/images/opfunc-2in1out_numberConstant100.png';
import Opfunc2in1outImg_TimeIntegrator from './atoms/images/opfunc-2in1out_timeIntegrator.png';
import type {GoogleAnalyticsContextProps} from '../../../modules/tracking/GoogleAnalyticsContext';
import GoogleAnalyticsContext from '../../../modules/tracking/GoogleAnalyticsContext';

function interpolate(x, startValue, finalValue) {
  return startValue + x * (finalValue - startValue);
}

const BOX_CONGRATULATION = 1;
const BOX_KEYBOARD_EXPLAIN = 2;
const BOX_KEYBOARD_DATA = 3;
const BOX_OPERATORS = 4;
const BOX_OPERATOR_COLOR = 5;
const BOX_LEDRING = 6;
const BOX_LEDRING_ACTION = 7;
const BOX_LEDRING_SUCCESS = 8;
const BOX_VALUEINPUT = 9;
const BOX_VALUEINPUT_DATA = 10;
const BOX_VALUEINPUT_SUCCESS = 11;
const BOX_POWER = 12;
const BOX_LETSPLAY = 13;
const BOX_FINISHED = 99;

export default function InteractiveAssembly() {
  const isPhone = useIsPhone();
  const mainDiv = useRef();
  const positionMain = ParallaxHelper.positionFromTypeAndRef('elementTopEnter', mainDiv);
  const scrollY = ParallaxHelper.useScrollY();
  const [showBox, setShowBox] = useState(BOX_CONGRATULATION);
  const [boxHidden, setBoxHidden] = useState(true);
  const offsetFactor = isPhone ? 0.7 : 1;
  const scrollenter1Section = ParallaxHelper.sectionScrollStats(positionMain + 200 * offsetFactor, positionMain + 400 * offsetFactor, scrollY);
  const scrollenter1 = scrollenter1Section.scrollRelativeCapped;
  const scrollenter2 = ParallaxHelper.sectionScrollStats(positionMain + 300 * offsetFactor, positionMain + 500 * offsetFactor,
      scrollY).scrollRelativeCapped;
  const scrollenter3Section = ParallaxHelper.sectionScrollStats(positionMain + 400 * offsetFactor, positionMain + 600 * offsetFactor, scrollY);
  const scrollenter3 = scrollenter3Section.scrollRelativeCapped;
  const scale = 50;
  if (boxHidden && scrollenter3Section.scrollOffsetBottom > 50) {
    setBoxHidden(false);
  }
  if (!boxHidden && scrollenter1Section.scrollOffsetTop < -400) {
    setBoxHidden(true);
  }
  const intvalue1 = !boxHidden ? 1 : scrollenter1;
  const intvalue2 = !boxHidden ? 1 : scrollenter2;
  const intvalue3 = !boxHidden ? 1 : scrollenter3;
  const fadeOut = !boxHidden && showBox >= BOX_CONGRATULATION && showBox < BOX_LETSPLAY;
  const keyboardHighlight = !boxHidden && [BOX_KEYBOARD_EXPLAIN, BOX_KEYBOARD_DATA, BOX_LEDRING_ACTION, BOX_LEDRING_SUCCESS].includes(showBox);
  const operatorHighlight = !boxHidden && [BOX_OPERATORS].includes(showBox);
  const colorOperatorHighlight = !boxHidden && [BOX_OPERATORS, BOX_OPERATOR_COLOR].includes(showBox);
  const ledringHighlight = !boxHidden && [BOX_LEDRING, BOX_LEDRING_ACTION, BOX_LEDRING_SUCCESS].includes(showBox);
  const valueinputHighlight = !boxHidden && [BOX_VALUEINPUT, BOX_VALUEINPUT_DATA, BOX_VALUEINPUT_SUCCESS].includes(showBox);
  const powerHighlight = !boxHidden && [BOX_POWER].includes(showBox);
  const backDisabled = showBox <= BOX_CONGRATULATION;
  const forwardDisabled = showBox >= BOX_LETSPLAY;

  const [keyboardData, setKeyboardData] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [valueInputData, setValueInputData] = useState(0);
  const [colorAnimation, setColorAnimation] = useState(false);

  function onKeyboardData(data: [boolean]) {
    setKeyboardData(data);
  }

  useEffect(() => {
    if (keyboardData.some(b => b)) {
      if (showBox === BOX_KEYBOARD_EXPLAIN) {
        trackInteraction(BOX_KEYBOARD_DATA, 'keyboard pressed');
        setShowBox(BOX_KEYBOARD_DATA);
      }
      if (showBox === BOX_LEDRING_ACTION) {
        trackInteraction(BOX_LEDRING_SUCCESS, 'led light action');
        setShowBox(BOX_LEDRING_SUCCESS);
      }
    }
  }, [showBox, keyboardData]);

  function onValueInputData(data) {
    setValueInputData(data);
  }

  useEffect(() => {
      if (showBox === BOX_VALUEINPUT_DATA && valueInputData >= 1.0) {
        trackInteraction(BOX_VALUEINPUT_SUCCESS, 'value_slider to one');
        setShowBox(BOX_VALUEINPUT_SUCCESS);
      }
  }, [showBox, valueInputData]);

  function startColorAnimation() {
    setColorAnimation(0);
    let handler = setInterval(() => {
      setColorAnimation((val) => {
        if (val > 2) {
          clearInterval(handler);
          return false;
        } else {
          return val + 0.1;
        }
      });
    }, 100);
  }

  const black = {red: 0, green: 0, blue: 0};

  function getColorAnimationColor(value) {
    const baseColors = [
      {red: 255, green: 0, blue: 0},
      {red: 255, green: 127, blue: 0},
      {red: 255, green: 255, blue: 0},
      {red: 127, green: 255, blue: 0},
      {red: 0, green: 255, blue: 0},
      {red: 0, green: 255, blue: 127},
      {red: 0, green: 255, blue: 255},
      {red: 0, green: 127, blue: 255},
      {red: 0, green: 0, blue: 255},
      {red: 127, green: 0, blue: 255},
      {red: 255, green: 0, blue: 255},
      {red: 255, green: 0, blue: 127}
    ];
    let colors = baseColors.map((col, inx) => {
      const inpx = inx / baseColors.length;
      return value <= 1 ? (inpx < value ? col : black) : (inpx < (2 - value) ? col : black);
    });
    return colors;
  }

  const [timeIntegration, setTimeIntegration] = useState(0);

  useEffect(() => {
    let stop = false;

    function increaseTimeIntegrator() {
      setValueInputData(vid => {
        setTimeIntegration((timeint) => {
          return timeint + vid * 0.2;
        });
        return vid;
      });
      if (!stop) {
        setTimeout(increaseTimeIntegrator, 200);
      }
    }

    increaseTimeIntegrator();
    return () => {
      stop = true;
    };
  }, []);

  function HSVtoRGB(H, S, V) {
    function mix(a, b, v) {
      return (1 - v) * a + v * b;
    }

    function fmod(a, b) {
      return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
    }

    H = fmod(H, 360);
    var V2 = V * (1 - S);
    var r = ((H >= 0 && H <= 60) || (H >= 300 && H <= 360)) ? V : ((H >= 120 && H <= 240) ? V2 : ((H >= 60 && H <= 120)
                                                                                                  ? mix(V, V2, (H - 60) / 60)
                                                                                                  : ((H >= 240 && H <= 300) ? mix(V2, V,
            (H - 240) / 60) : 0)));
    var g = (H >= 60 && H <= 180) ? V : ((H >= 240 && H <= 360) ? V2 : ((H >= 0 && H <= 60) ? mix(V2, V, H / 60) : ((H >= 180 && H <= 240) ? mix(V,
        V2, (H - 180) / 60) : 0)));
    var b = (H >= 0 && H <= 120) ? V2 : ((H >= 180 && H <= 300) ? V : ((H >= 120 && H <= 180) ? mix(V2, V, (H - 120) / 60) : ((H >= 300 && H <= 360)
                                                                                                                              ? mix(V, V2,
            (H - 300) / 60)
                                                                                                                              : 0)));

    return {
      red: Math.round(r * 255),
      green: Math.round(g * 255),
      blue: Math.round(b * 255)
    };
  }

  // Tracking
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);

  function startTutorial(restart) {
    setShowBox(BOX_KEYBOARD_EXPLAIN);
    googleAnalytics.event('tutorial_begin', {
      ...googleAnalytics.identifierParams('main interactive assembly'),
      mode: restart ? 'restart' : 'default'
    });
  }

  function continueToNext(nextBoxId) {
    setShowBox(nextBoxId);
    googleAnalytics.event('tutorial_continue', {
      ...googleAnalytics.identifierParams('main interactive assembly'),
      new_box_id: nextBoxId
    });
  }

  function trackInteraction(nextBoxId, achievement_id) {
    googleAnalytics.event('tutorial_achievement', {
      ...googleAnalytics.identifierParams('main interactive assembly'),
      new_box_id: nextBoxId,
      achievement_id
    });
  }

  function finishTutorial() {
    setShowBox(BOX_FINISHED);
    googleAnalytics.event('tutorial_complete', {
      ...googleAnalytics.identifierParams('main interactive assembly')
    });
  }

  function onPrevious() {
    if (backDisabled) {
      return;
    }
    setShowBox(showBox - 1);
    googleAnalytics.event('tutorial_navigate', {
      ...googleAnalytics.identifierParams('main interactive assembly'),
      box_id: showBox,
      action: 'previous'
    });
  }

  function onNext() {
    if (forwardDisabled) {
      return;
    }
    setShowBox(showBox + 1);
    googleAnalytics.event('tutorial_navigate', {
      ...googleAnalytics.identifierParams('main interactive assembly'),
      box_id: showBox,
      action: 'next'
    });
  }

  const color = HSVtoRGB(100 * timeIntegration, 1, 1);
  const ledringcolors = colorAnimation === false ? keyboardData.map(val => val ? color : black) : getColorAnimationColor(colorAnimation);
  return (
      <div ref={mainDiv} className={styles.InteractiveAssembly}>
        <KeyboardAtom onValueChanged={onKeyboardData} highlight={keyboardHighlight} x={620} y={interpolate(intvalue1, 600, 400)} rotation={0}
                      scale={scale}/>
        <Operator2in1outAtom highlight={colorOperatorHighlight} x={interpolate(intvalue1, -1500, 359)} y={interpolate(intvalue1, -500, 249)}
                             rotation={-60} scale={scale}/>
        <Opfunc2in1out symbolImage={Opfunc2in1outImg_BrightnessHue} highlight={colorOperatorHighlight} x={interpolate(intvalue1, -1500, 359)}
                       y={interpolate(intvalue1, -500, 249)} rotation={-60} scale={scale}/>
        <Operator2in1outAtom highlight={operatorHighlight} x={interpolate(intvalue2, 2500, 402)} y={interpolate(intvalue2, -500, 174)} rotation={-120}
                             scale={scale}/>
        <Opfunc2in1out symbolImage={Opfunc2in1outImg_NumberConstant100} highlight={operatorHighlight} x={interpolate(intvalue2, 2500, 402)}
                       y={interpolate(intvalue2, -500, 174)} rotation={-120} scale={scale}/>
        <LedRingAtom colors={ledringcolors} highlight={ledringHighlight} x={interpolate(intvalue2, -1500, 273)} y={interpolate(intvalue2, -500, 249)}
                     rotation={0} scale={scale}/>
        <SimpleValueInputAtom onValueChanged={onValueInputData} highlight={valueinputHighlight} x={interpolate(intvalue3, 1500, 489)}
                              y={interpolate(intvalue3, 500, 174)} rotation={0} scale={scale}/>
        <Operator2in1outAtom highlight={operatorHighlight} x={interpolate(intvalue3, 2000, 577)} y={interpolate(intvalue3, 1500, 174)} rotation={-60}
                             scale={scale}/>
        <Opfunc2in1out symbolImage={Opfunc2in1outImg_TimeIntegrator} highlight={operatorHighlight} x={interpolate(intvalue3, 2000, 577)}
                       y={interpolate(intvalue3, 1500, 174)} rotation={-60} scale={scale}/>
        <PowerAtom highlight={powerHighlight} x={interpolate(intvalue3, 2000, 621)} y={interpolate(intvalue3, -2000, 98)} rotation={-60}
                   scale={scale}/>
        <div className={styles.fadeOut + (fadeOut ? ' ' + styles.showFadeout : '')}></div>
        <div className={styles.box + ' ' + styles.stepCongratulation + ((showBox === BOX_CONGRATULATION && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Super, Du hast Dein erstes TechAtom Programm zusammengebaut!</h1>
          <p>Gleich kannst Du loslegen. Wir zeigen Dir kurz, wie das Programm funktioniert.</p>
          <button onClick={() => startTutorial()}>Start</button>
        </div>
        <div className={styles.box + ' ' + styles.stepKeyboard + ((showBox === BOX_KEYBOARD_EXPLAIN && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Eingabe: Die Tastatur</h1>
          <p>Die Tastatur ist ein Eingabegerät. Sie erzeugt Daten, die von anderen Bausteinen genutzt werden können. </p>
          <p><strong>Drück eine der Tasten, dann geht es weiter.</strong></p>
        </div>
        <div className={styles.box + ' ' + styles.stepKeyboardData + ((showBox === BOX_KEYBOARD_DATA && !boxHidden) ? ' ' + styles.showBox : '')}>
          <p>Hier siehst Du, welche Daten die Tastatur erzeugt: </p>
          <div>
            <BooleanArray data={keyboardData}/>
          </div>
          <p><strong>Tipp:</strong> Du kannst auch die Buchstaben A, S, D, F, G, H, J und K drücken, um die TechAtom-Tastatur zu bedienen.</p>
          <button onClick={() => continueToNext(BOX_OPERATORS)}>Weiter</button>
        </div>
        <div className={styles.box + ' ' + styles.stepOperators + ((showBox === BOX_OPERATORS && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Datenverarbeitung mit Operatoren </h1>
          <p>Die Operatoren übernehmen bei TechAtom die Datenverarbeitung.
            Das heißt sie verändern Daten so, dass die Ausgabe das ausgibt,
            was Du möchtest.</p>
          <p>Operatoren nutzen Funktionen, die ihnen sagen, was genau sie machen sollen. 
            Jede Funktion erkennst Du an ihrem einzigartigen Symbol.</p>
            <button onClick={() => continueToNext(BOX_OPERATOR_COLOR)}>Weiter</button>
            </div>
        <div className={styles.box + ' ' + styles.stepOperatorColor + ((showBox === BOX_OPERATOR_COLOR && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Der Farb-Kombinierer</h1>
          <p>Bei diesem Operator benutzen wir die Farb-Kombinierer-Funktion. 
            In unserem Programm wollen wir nämlich den Leuchtring in den schönsten Farben leuchten lassen.</p>

          <button onClick={() => {
            continueToNext(BOX_LEDRING);
            startColorAnimation();
          }}>Weiter
          </button>
        </div>
        <div className={styles.box + ' ' + styles.stepLedRing + ((showBox === BOX_LEDRING && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Ausgabe: Der Leuchtring</h1>
          <p>Jetzt kommen wir zu unserer ersten Datenausgabe: Wir nutzen den Leuchtring, 
            mit dem wir farbige LEDs zum Leuchten bringen können.</p>
          <button onClick={() => continueToNext(BOX_LEDRING_ACTION)}>Weiter</button>
        </div>
        <div className={styles.box + ' ' + styles.stepLedRingAction + ((showBox === BOX_LEDRING_ACTION && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Und, Action!</h1>
          <p>Jetzt kommt endlich Dein Programm zum Einsatz. Hast Du schon eine Idee, wie die Steine funktionieren?</p>
          <p><strong>Drück die Tasten der Tastatur, um den Leuchtring aufleuchten zu lassen. Danach geht's weiter.</strong></p>
        </div>
        <div className={styles.box + ' ' + styles.stepLedRingSuccess + ((showBox === BOX_LEDRING_SUCCESS && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Super, das klappt gut!</h1>
          <p>Durch die Verbindung von Tastatur mit den anderen Bausteinen werden 
            die Daten erst zum Operator und dann zum Leuchtring weitergegeben. Die Farb-Kombinierer-Funktion sorgt 
            dabei dafür, dass die LEDs nicht einfach weiß aufleuchten.</p>
          <button onClick={() => continueToNext(BOX_VALUEINPUT)}>Weiter</button>
        </div>
        <div className={styles.box + ' ' + styles.stepValueInput + ((showBox === BOX_VALUEINPUT && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Eingabe: Drehregler</h1>
          <p>Noch ein Eingabegerät - der Drehregler. Den kannst Du überall dort einsetzen, wo Du etwas einstellen möchtest.</p>
          <p>Der Drehregler erzeugt in Deinem Programm eine Zahl zwischen null und eins. Diese kannst Du so verändern, 
            wie Du sie für Dein Programm brauchst.</p>
            <button onClick={() => continueToNext(BOX_VALUEINPUT_DATA)}>Ausprobieren</button>
        </div>
        <div className={styles.box + ' ' + styles.stepValueInputData + ((showBox === BOX_VALUEINPUT_DATA && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Drehregler einstellen</h1>
          <p>Hier siehst Du welche Zahl der Drehregler erzeugt: </p>
          <div>
            <SingleNumber data={valueInputData}/>
          </div>
          <p>Klick auf den Drehregler und beweg Deine Maus hoch oder runter, um den Wert zu ändern.</p>
          <p><strong>Stell' den Wert auf 1 ein, schon geht's weiter.</strong></p>
        </div>
        <div className={styles.box + ' ' + styles.stepValueInputSuccess + ((showBox === BOX_VALUEINPUT_SUCCESS && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Jetzt wird's bunt</h1>
          <p>Das war doch gar nicht schwer, gut. In Deinem Programm sorgt der Drehregler dafür, dass 
            die Farben der Ausgabe sich ständig ändern. Das kannst Du gleich ausprobieren.</p>
            <button onClick={() => continueToNext(BOX_POWER)}>Weiter</button>
            </div>
        <div className={styles.box + ' ' + styles.stepPower + ((showBox === BOX_POWER && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Es werde Licht</h1>
          <p>Eine Kleinigkeit habe ich noch für Dich: Dies hier ist das <strong>Strom-Atom</strong>. 
            Es versorgt Dein Programm mit Energie. Deshalb darf es in keinem Programm fehlen.</p>
            <button onClick={() => continueToNext(BOX_LETSPLAY)}>Weiter</button>
             </div>
        <div className={styles.box + ' ' + styles.stepLetsplay + ((showBox === BOX_LETSPLAY && !boxHidden) ? ' ' + styles.showBox : '')}>
          <h1>Geschafft!</h1>
          <p>Das war es erstmal, was ich Dir erzählen wollte. :) Jetzt kannst Du selber ausprobieren.</p>
          <p>Viel Spaß beim Spielen!</p>
          <button onClick={() => finishTutorial()}>Los geht's</button>
          </div>
        <div className={styles.box + ' ' + styles.backButton + ((showBox !== BOX_FINISHED && !boxHidden) ? ' ' + styles.showBox : '') + (backDisabled
                                                                                                                                         ? ' '
                        + styles.boxDisabled
                                                                                                                                         : '')}
             onClick={onPrevious}>
          <ChevronLeft/>
        </div>
        <div className={styles.box + ' ' + styles.forwardButton + ((showBox !== BOX_FINISHED && !boxHidden) ? ' ' + styles.showBox : '')
                        + (forwardDisabled ? ' ' + styles.boxDisabled : '')}
             onClick={onNext}>
          <ChevronRight/>
        </div>
        <div className={styles.box + ' ' + styles.restartButton + ((showBox === BOX_FINISHED && !boxHidden) ? ' ' + styles.showBox : '')}
             onClick={() => {
               startTutorial(true);
               setBoxHidden(false);
             }}>
          Neu starten
        </div>
      </div>
  );
}