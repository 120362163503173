import * as React from 'react';
import '../basic.scss';
import './DefaultPageContent.scss';

export default class DefaultPageContent extends React.Component {

  render() {
    return (
        <div className="DefaultPageContent">
          <div className="page-container">
            {this.props.children}
          </div>
        </div>
    );
  }
}