import * as React from 'react';
import styles from './ManufacturingIcon.module.scss';

export default class ManufacturingIcon extends React.Component {

  render() {
    return (
        <div className={styles.ManufacturingIcon}>
          <img src={this.props.icon} alt="Icon"/>
          <h1>{this.props.title}</h1>
        </div>
    );
  }
}