import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import LogoImg from './assets/images/Icon_512px.png';
import Helmet from 'react-helmet';
import type {AppConfig} from './modules/config/AppConfigContext';
import AppConfigContext from './modules/config/AppConfigContext';

export default function DefaultMetaTag() {
  const appConfig: AppConfig = useContext(AppConfigContext);
  const location = useLocation();
  console.log('location', location);
  return (
      <Helmet>
        {appConfig.robotsIndexing && <meta name="robots" content="index, follow"/> }
        {!appConfig.robotsIndexing && <meta name="robots" content="noindex"/> }
        
        <title>TechAtom | &gt;_ Die Programmiersprache zum Anfassen</title>
        <meta
            name="description"
            content="Tauche ein in die Welt von TechAtom und entdecke unsere intelligenten Bausteine, mit denen Du spielerisch Programmieren lernen kannst! Worauf wartest Du noch?"
        />

        { /*  Essential META Tags */}
        <meta property="og:title" content="TechAtom | >_ Die Programmiersprache zum Anfassen"/>
        <meta property="og:description"
              content="Tauche ein in die Welt von TechAtom und entdecke unsere intelligenten Bausteine, mit denen Du spielerisch Programmieren lernen kannst! Worauf wartest Du noch?"/>
        <meta property="og:site_name" content="TechAtom | >_ Die Programmiersprache zum Anfassen"/>
        <meta property="og:locale" content="de_DE"/>

        <link rel="canonical" href={appConfig.urlOrigin + location.pathname}/>
        <meta property="og:image" content={appConfig.urlOrigin + LogoImg}/>
        <meta property="og:url" content={appConfig.urlOrigin + location.pathname}/>

        <meta name="twitter:card" content="summary"/>


        { /*  Non-Essential, But Recommended */}
        <meta name="twitter:image:alt" content="Alt text for image"/>


        { /*  Non-Essential, But Required for Analytics */}
        {false && <meta property="fb:app_id" content="your_app_id"/>}
        <meta name="twitter:site" content="@taprolabsde"/>

      </Helmet>
  );
}