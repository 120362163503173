import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom';
import Main from './pages/main/Main';
import Shop from './pages/shop/Shop';
import Betatester from './pages/Betatester';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import CookieConsentNotification from './modules/dataprivacy/components/CookieConsentNotification';
import {DataPrivacyConsumer, DataPrivacyProvider} from './modules/dataprivacy/DataPrivacyContext';
import DataPrivacySettings from './modules/dataprivacy/components/DataPrivacySettings';
import type {DataPrivacyContextProps} from './modules/dataprivacy/DataPrivacyContext';
import {GoogleAnalyticsProvider} from './modules/tracking/GoogleAnalyticsContext';
import DefaultMetaTag from './DefaultMetaTags';
import AppConfigContext, {AppConfigProvider} from './modules/config/AppConfigContext';
import type {AppConfig} from './modules/config/AppConfigContext';

function App() {
  return (
      <div id="app" className="App">
        <AppConfigProvider>
          <Router>
            <DefaultMetaTag />
            <DataPrivacyProvider value={{showConsentNotification: true}}>
              <AppConfigContext>
                {(appConfig: AppConfig) =>
                  <GoogleAnalyticsProvider gaMeasurementId={appConfig.gaMeasurementId}>
                    <div>
                      <Switch>
                        <Route exact path="/">
                          <Main/>
                        </Route>
                        <Route path="/shop">
                          <Shop/>
                        </Route>
                        <Route path="/betatester">
                          <Betatester/>
                        </Route>
                        <Route path="/shop">
                          <Shop/>
                        </Route>
                        <Route path="/datenschutz">
                          <Datenschutz/>
                        </Route>
                        <Route path="/impressum">
                          <Impressum/>
                        </Route>
                      </Switch>
                    </div>
                    <DataPrivacyConsumer>
                      {(dataPrivacy: DataPrivacyContextProps) =>
                          <React.Fragment>
                            {dataPrivacy.showConsentNotification && <CookieConsentNotification/>}
                            {dataPrivacy.settingsVisible && <DataPrivacySettings/>}
                          </React.Fragment>
                      }
                    </DataPrivacyConsumer>
                  </GoogleAnalyticsProvider>
                }
              </AppConfigContext>
            </DataPrivacyProvider>
          </Router>
        </AppConfigProvider>
      </div>
  );
}

export default App;
