import * as React from 'react';
import styles from './EvaHeaderStickyNote.module.scss';
import {useState} from 'react';

export default function EvaHeaderStickyNote(props) {
  const [rotation, setRotation] = useState(Math.random()*4 - 2);
  return (<div onMouseEnter={props.onMouseEnter} onClick={props.onClick} className={styles.EvaHeaderStickyNote} style={{'--sticky-note-rotation': rotation+'deg'}}>
    <img className={styles.noteImage} src={props.noteImage} alt=""/>
    <img className={styles.hoverImage} src={props.hoverImage} alt=""/>
  </div>);
}