import * as React from 'react';
import SectionHeadline from '../components/SectionHeadline';
import EmbeddedVideo from '../../../components/EmbeddedVideo';
import BigActionButton from '../../../components/BigActionButton';
import styles from './DataProcessingSection.module.scss';
import DefaultContentWrapper from '../../../components/DefaultContentWrapper';
import EvaHeaderStickyNote from './EvaHeaderStickyNote';
import EvaImageNoteInput from './images/inputNote.png';
import EvaImageNoteInputMobile from './images/inputNote_mobile.png';
import EvaImageNoteProcessing from './images/processingNote.png';
import EvaImageNoteProcessingMobile from './images/processingNote_mobile.png';
import EvaImageNoteOutput from './images/outputNote.png';
import EvaImageNoteOutputMobile from './images/outputNote_mobile.png';
import EvaImageHoverInput from './images/dateninput.gif';
import EvaImageHoverProcessing from './images/datenverarbeitung.gif';
import EvaImageHoverOutput from './images/datenoutput.gif';
import AtomButtonImage from './images/product/Button.jpg';
import AtomNumberDialImage from './images/product/Drehregler.jpg';
import AtomOperatorImage from './images/product/2in1Operator Draufsicht.jpg';
import AtomOpfuncImage from './images/product/2in1Funktionen.jpg';
import AtomDisplayImage from './images/product/Display.jpg';
import AtomLeuchtringImage from './images/product/Leuchtring.jpg';

import StickyAtomFoto from './StickyAtomFoto';
import {useIsPhone} from '../../../TechAtomHooks';

import SwiperCore, {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import InteractiveAssembly from './InteractiveAssembly';
import type {GoogleAnalyticsContextProps} from '../../../modules/tracking/GoogleAnalyticsContext';
import {useContext, useRef} from 'react';
import GoogleAnalyticsContext from '../../../modules/tracking/GoogleAnalyticsContext';
import useHoverSectionTracker from '../../../modules/tracking/useHoverSectionTracker';
import ExampleVideoCard from '../section-examples/ExampleVideoCard';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const galleryData = [
  {
    key: 'input',
    headerNoteImage: EvaImageNoteInput,
    headerNoteImageMobile: EvaImageNoteInputMobile,
    headerHoverImage: EvaImageHoverInput,
    atoms: [
      {
        namekey: 'button',
        image: AtomButtonImage,
        content: <div>
          <p><strong>TechAtom:</strong> Button</p>
          <p>
            Simpel, aber 'oho'​: Setze mit dem Button eine Stoppuhr zurück,
            steuere Datenflüsse oder nutze ihn als Lichtsteuerung. <br/>
            <strong>Dein TechAtom - Deine Ideen!</strong>
          </p>
        </div>

      },
      {
        namekey: 'valueinput',
        image: AtomNumberDialImage,
        content: <div>
          <p><strong>TechAtom:</strong> Drehregler</p>
          <p>
            Nutze den Drehregler, um allerhand in Deinem Programm
            einzustellen: Z.B. die Helligkeit oder Farben des Leuchtrings, 
            die Größe von Abständen, Geschwindigkeiten und vieles mehr!
          </p>
        </div>

      }
    ]
  },
  {
    key: 'processing',
    headerNoteImage: EvaImageNoteProcessing,
    headerNoteImageMobile: EvaImageNoteProcessingMobile,
    headerHoverImage: EvaImageHoverProcessing,
    atoms: [
      {
        namekey: 'operator',
        image: AtomOperatorImage,
        content: <div>
          <p><strong>TechAtom:</strong> Operator</p>
          <p>
            Gestatten: <em>Operator.</em> <br/>
            Ein ​zentraler Baustein in Deinem TechAtom-Programm - ohne Operatoren funktioniert bei TechAtom keine Datenverarbeitung.
          </p>
        </div>

      },
      {
        namekey: 'function',
        image: AtomOpfuncImage,
        content: <div>
          <p><strong>TechAtom:</strong> Funktionen</p>
          <p>
            Kleine Bausteine, riesige Möglichkeiten: Von mathematischen
            Berechnungen über Arrayfunktionen bis hin zu if-/else
            Anweisungen bringen sie die Funktionalität auf die Operatoren!
          </p>
        </div>

      }
    ]
  },
  {
    key: 'output',
    headerNoteImage: EvaImageNoteOutput,
    headerNoteImageMobile: EvaImageNoteOutputMobile,
    headerHoverImage: EvaImageHoverOutput,
    atoms: [
      {
        namekey: 'display',
        image: AtomDisplayImage,
        content: <div>
          <p><strong>TechAtom:</strong> Display</p>
          <p>
            Das Farbdisplay kann Text und Grafiken anzeigen.
            Damit kannst Du Dir Ergebnisse Deines Programms anzeigen lassen oder herausfinden, 
            wo etwas nicht so klappt, wie Du es möchtest.
          </p>
        </div>
      },
      {
        namekey: 'led-ring',
        image: AtomLeuchtringImage,
        content: <div>
          <p><strong>TechAtom:</strong> Leuchtring</p>
          <p>
            Bringt Deine Daten mit 24 LEDs in mehr als 16Mio. Farben zum Leuchten.
          </p>
          <p>
            Was sollen wir sagen: Selten haben Daten so spektakulär ausgesehen! :)
          </p>
        </div>
      }
    ]
  }
];

export default function DataProcessingSection() {
  const isPhone = useIsPhone();
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  function onSlideChange(columnKey, e) {
    googleAnalytics.event('slide', {
      ...googleAnalytics.identifierParams('main data', 'atoms-gallery', 'col-'+columnKey),
      index_offset: e.previousIndex + ' -> ' + e.activeIndex
    });
  }

  const tracker = useHoverSectionTracker('main data atoms-gallery', galleryData.flatMap(columnData => {
    return [
        'column-'+columnData.key,
        ...columnData.atoms.map(atom => 'atom-'+atom.namekey)
    ]
  }));

  const mainDiv = useRef();
  useSectionViewTracker('main data-processing', mainDiv, {});

  return (
      <div ref={mainDiv} className={styles.DataProcessingSection}>
        <DefaultContentWrapper>
          <SectionHeadline title="TechAtom und die<br /> Welt der Daten" darkBackground/>
          <InteractiveAssembly/>
          <div className={styles.text}>
            <p>Unsere TechAtome sind Meister der Datenverarbeitung: Es gibt TechAtome zur Dateneingabe, zur
              Datenverarbeitung und zur Ausgabe -
              und alle haben unterschiedliche Eigenschaften und Fähigkeiten! Eine kleine Auswahl siehst Du hier:</p>
          </div>
          <div className={styles.evaGallery}>
            {!isPhone && galleryData.map((columnData, colindex) =>
                <div key={'column' + colindex} className={styles.column}>
                  <EvaHeaderStickyNote onMouseEnter={tracker.setupMouseEnter('column-'+columnData.key)} onClick={tracker.setupClick('column-'+columnData.key)} noteImage={columnData.headerNoteImage} hoverImage={columnData.headerHoverImage}/>
                  {columnData.atoms.map((atom, atomindex) =>
                      <StickyAtomFoto key={'c' + colindex + 'atom' + atomindex} image={atom.image} onMouseEnter={tracker.setupMouseEnter('atom-'+atom.namekey)} onClick={tracker.setupClick('atom-'+atom.namekey)}>
                        {atom.content}
                      </StickyAtomFoto>
                  )}
                </div>
            )}
            {isPhone && galleryData.map((columnData, colindex) =>
                <Swiper key={'column_' + columnData.key} navigation onSlideChange={onSlideChange.bind(this, columnData.key)}> {[
                  <SwiperSlide key={'header' + colindex}>
                    <div><img src={columnData.headerNoteImageMobile} alt=""/></div>
                  </SwiperSlide>,
                  ...columnData.atoms.flatMap((atom, atomindex) => {
                    return [
                      <SwiperSlide key={'c' + colindex + 'a' + atomindex + 'img'}>
                        <div><img src={atom.image} alt=""/></div>
                      </SwiperSlide>,
                      <SwiperSlide key={'c' + colindex + 'a' + atomindex + 'text'}>
                        <div>{atom.content}</div>
                      </SwiperSlide>
                    ];
                  })
                ]}
                </Swiper>
            )}
          </div>
          <div className={styles.moreInfoButton}>
            <BigActionButton toLink="/betatester" title="Mehr Info -&gt;"/>
          </div>
        </DefaultContentWrapper>
      </div>
  );
}