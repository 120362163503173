import * as React from 'react';
import {AtomFrame} from './Atom';
import styles from './SimpleValueInputAtom.module.scss';
import type {AtomProps} from './Atom';
import {useState} from 'react';
import {ArrowDropDown, ArrowDropUp} from '@material-ui/icons';

type Props = {
  ...AtomProps,
  onValueChanged: ?(number) => void
}

export default function SimpleValueInputAtom(props) {

  const [mouseDrag, setMouseDrag] = useState(0);
  const [value, setValue] = useState(0);
  const [mouseOriginalY, setMouseOriginalY] = useState(0);
  const [originalValue, setOriginalValue] = useState(0);
  
  function getClientY(event) {
    if (['touchstart', 'touchmove'].indexOf(event.type) !== -1) {
      return event.touches[0].pageY;
    }
    return event.pageY;
  }
  
  function handleMouseMove(event) {
    setMouseOriginalY((morgY) => {
      const valueShift = -(getClientY(event) - morgY) / 200;
      setOriginalValue((orgvalue) => {
        const newVal = Math.max(Math.min(orgvalue + valueShift, 1), 0);
        setValue(() => {
          if (props.onValueChanged) {
            props.onValueChanged(newVal);
          }
          return newVal;
        });
        return orgvalue;
      });
      return morgY;
    });
  };

  function handleMouseUp(event) {
    setMouseDrag(false);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('touchend', handleMouseUp);
    document.removeEventListener('touchmove', handleMouseMove);
  };

  function handleMouseDown(event) {
    setMouseDrag(true);
    setMouseOriginalY(getClientY(event.nativeEvent));
    setValue((value) => {
      setOriginalValue(value);
      return value;
    });
    document.addEventListener('mousemove', handleMouseMove, { passive: false });
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleMouseMove, { passive: false });
    document.addEventListener('touchend', handleMouseUp);
  };
 
  return (
      <AtomFrame 
          {...props}
          className={styles.SimpleValueInputAtom + (mouseDrag ? ' '+styles.userInputActive : '')} 
      >
        <div 
            className={styles.valueDial}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            style={{
              '--atom-dial-value': value
            }}
        />
      </AtomFrame>
  )
};