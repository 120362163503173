import * as React from 'react';
import {useRef} from 'react';
import SectionHeadline from '../components/SectionHeadline';
import ExampleVideoCard from './ExampleVideoCard';
import StoppuhrImg from './images/Stoppuhr.png';
import StoppuhrAnimatedImg from './images/Stoppuhr.gif';
import AbstandswarnerImg from './images/Abstandswarner.png';
import AbstandswarnerAnimatedImg from './images/Abstandswarner.gif';
import LichtorgelImg from './images/Lichtorgel.png';
import LichtorgelAnimatedImg from './images/lichtorgel.gif';
import DefaultContentWrapper from '../../../components/DefaultContentWrapper';
import styles from './ExamplesSection.module.scss';
import ParallaxHelper from '../../../modules/helper/ParallaxHelper';
import useHoverSectionTracker from '../../../modules/tracking/useHoverSectionTracker';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';

export default function ExamplesSection() {
  const mainDiv = useRef();
  const positions = ParallaxHelper.simpleSingleElementOffsetPositions('elementTopEnter', mainDiv, 800);
  const scrollenter = ParallaxHelper.useScrollStats(positions.start, positions.end);
  
  const tracker = useHoverSectionTracker('main examples', [
    'stopp', 'distance', 'light'
  ]);

  useSectionViewTracker('main examples', mainDiv, {});

  return (
      <div className={styles.ExamplesSection} ref={mainDiv} style={{
        '--scroll-enter': scrollenter.scrollRelativeCapped
      }}>
        <div className={styles.blendingBackground}>
          <DefaultContentWrapper>
            <SectionHeadline title="TechAtom in Aktion" subtitle="Eine kleine Auswahl großer Möglichkeiten"/>
            <ExampleVideoCard onMouseEnter={tracker.setupMouseEnter('stopp')} onClick={tracker.setupClick('stopp')} name="Stoppuhr" image={StoppuhrImg} imageAnimated={StoppuhrAnimatedImg} vimeoVideoId="473054862"/>
            <div className={styles.mobileOnlyLine}></div>
            <ExampleVideoCard onMouseEnter={tracker.setupMouseEnter('distance')} onClick={tracker.setupClick('distance')} name="Abstandswarner" image={AbstandswarnerImg} imageAnimated={AbstandswarnerAnimatedImg} vimeoVideoId="473054866"/>
            <div className={styles.mobileOnlyLine}></div>
            <ExampleVideoCard onMouseEnter={tracker.setupMouseEnter('light')} onClick={tracker.setupClick('light')} name="Lichtorgel" image={LichtorgelImg} imageAnimated={LichtorgelAnimatedImg} vimeoVideoId="473054994"/>
          </DefaultContentWrapper>
        </div>
      </div>
  );
}