import * as React from 'react';
import styles from './ChatCarouselMessage.module.scss';

export default class ChatCarouselMessage extends React.Component {

  render() {
    const responseMessage = this.props.responseMessage;
    return (
        <div className={styles.ChatCarouselMessage + (responseMessage ? ' '+styles.response : '') }>
          {this.props.children}
        </div>
    );
  }
}