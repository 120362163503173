import * as React from 'react';
import './ProductNotReadyYet.scss';

export default class ProductNotReadyYet extends React.Component {

  render() {
    return (
        <div className="ProductNotReadyYet">
          <p>
            *Liebe/r Besucher/in,
          </p>
          <p>
            wir arbeiten mit Hochdruck daran, dass es TechAtom bald zu kaufen gibt und Du Dir in unserem Shop die
            "Programmiersprache zum Anfassen" nach Hause bestellen kannst. Bis es soweit ist, musst Du Dich leider noch ein wenig gedulden. Trage Dich
            gerne für unsere Benachrichtigungsmail ein, dann melden wir uns bei Dir, wenn TechAtom verfügbar ist - und zwar nur dann. :)
          </p>
          <p>
            Liebe Grüße,
          </p>
          <p>
            Dein TechAtom-Team
          </p>
        </div>
    );
  }
}