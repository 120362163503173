import * as React from 'react';
import styles from './SingleNumber.module.scss';

type Props = {
  data: [boolean]
};

export default function SingleNumber(props: Props) {
  return (
      <div className={styles.SingleNumber}>
        {props.data.toFixed(3)}
      </div>
  )
};