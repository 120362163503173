import * as React from 'react';
import styles from './BooleanArray.module.scss';

type Props = {
  data: [boolean]
};

export default function BooleanArray(props: Props) {
  return (
      <div className={styles.BooleanArray}>
        {props.data.map((val, inx) => 
            <div key={'bool'+inx} className={styles.boolIcon + ' ' + (val ? styles.trueValue : styles.falseValue)} >
              {val ? '1' : '0'}
            </div>
        )}
      </div>
  )
};