import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import IntroSection from './section-intro/IntroSection';
import ChatIntroSection from './section-chat/ChatIntroSection';
import ExamplesSection from './section-examples/ExamplesSection';
import DataProcessingSection from './section-data/DataProcessingSection';
import EducationExplainSection from './section-education/EducationExplainSection';
import ManufacturingSection from './section-manufacturing/ManufacturingSection';
import NewsletterSection from './sections/NewsletterSection';
import TaprolabsSection from './sections/TaprolabsSection';
import ToShopSection from './sections/ToShopSection';
import TopMenu from '../../components/TopMenu';
import Footer from '../../components/Footer';
import styles from './Main.module.scss';
import ParallaxHelper from '../../modules/helper/ParallaxHelper';
import useScrollToTop from '../../modules/helper/useScrollToTop';
import {useLocation} from 'react-router-dom';

export default function Main(props) {
  useScrollToTop();

  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#ausprobieren') {
      //debugger;
      const element = document.getElementById('ausprobieren');
      window.scrollTo({top: 0});
      window.setTimeout(() => {
        window.scrollTo({top: element.offsetTop, behavior: 'smooth'});
      }, 200);
    }
  }, [location.pathname]);

  const mainDiv = useRef();
  const positions = ParallaxHelper.simpleEnterLeavePositions(mainDiv);
  const backgroundmove = ParallaxHelper.useScrollStats(positions.enter, positions.leave);

  return <div className={styles.Main} ref={mainDiv} style={{
    '--scroll-background': backgroundmove.scrollRelativeCapped
  }}>
    <TopMenu whiteItems={true} outerContainerId={ "app" }/>
    {props.children}
    <a id="entdecken"/>
    {true &&
     <IntroSection/>
    }
    <a id="techatom"/>
    {true &&
     <ChatIntroSection/>
    }
    <a id="videos"/>
    {true &&
     <ExamplesSection/>
    }

    <a id="ausprobieren"/>
    {true &&
     <DataProcessingSection/>
    }
    <EducationExplainSection/>
    <a id="produktion"/>
    <ManufacturingSection/>
    <ToShopSection/>
    <a id="kontakt"/>
    <NewsletterSection/>
    <a id="team"/>
    <TaprolabsSection/>
    <Footer/>
  </div>;
}
