import * as React from 'react';
import SectionHeadline from '../components/SectionHeadline';
import styles from './NewsletterSection.module.scss';
import TwitterIcon from '../../../assets/icons/twitter-667462_640.png';
import YoutubeIcon from '../../../assets/icons/youtube_social_squircle_red.png';
import useScript from '../../../modules/helper/useScript';
import '../../../rapidmail.scss';
import type {GoogleAnalyticsContextProps} from '../../../modules/tracking/GoogleAnalyticsContext';
import {useContext, useRef, useState} from 'react';
import GoogleAnalyticsContext from '../../../modules/tracking/GoogleAnalyticsContext';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';

export default function NewsletterSection() {
  useScript('https://t08dd98dc.emailsys1a.net/form/126/2331/f845a4da45/embedded.js');
  
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);

  function onClick() {
    if (consent && email.length >= 5) {
      googleAnalytics.event('generate_lead', {
        ...googleAnalytics.identifierParams(
            'main', 'newsletter_subscribe'
        )
      });
    }
  }

  const mainDiv = useRef();
  useSectionViewTracker('main newsletter', mainDiv, {});

  return (
      <div ref={mainDiv} className={styles.NewsletterSection}>
        <SectionHeadline className={styles.headline} title="Nichts verpassen!" darkBackground/>
        <div className={styles.sectionContainer}>
          <div className={styles.register}>
            <h2>Melde Dich für unseren Newsletter an</h2>
            <div id="rmOrganism">
              <div className="rmEmbed rmLayout--vertical rmBase">
                <div data-page-type="formSubscribe" className="rmBase__body rmSubscription">
                  <form method="post" action="https://t08dd98dc.emailsys1a.net/126/2749/dcfd28cf4d/subscribe/form.html?_g=1604402060"
                        className="rmBase__content">
                    <div className="rmBase__container">
                      <div className="rmBase__section">
                        <div className="rmBase__el rmBase__el--input rmBase__el--label-pos-left" data-field="email">
                          <label htmlFor="email" className="rmBase__compLabel rmBase__compLabel--hideable">
                            E-Mail
                          </label>
                          <div className="rmBase__compContainer">
                            <input onChange={event => setEmail(event.target.value)} type="text" name="email" id="email" placeholder="max@techatom.de" className="rmBase__comp--input comp__input"/>
                            <div className="rmBase__compError"></div>
                          </div>
                        </div>
                      </div>
                      <div className="rmBase__section">
                        <div className="rmBase__el rmBase__el--consent" data-field="consent_text">
                          <div className="rmBase__comp--checkbox">
                            <label htmlFor="consent_text" className="vFormCheckbox comp__checkbox">
                              <input onChange={event => setConsent(event.target.value)} type="checkbox" value="yes" name="consent_text" id="consent_text" className="vFormCheckbox__input"/>
                              <div className="vFormCheckbox__indicator"></div>
                              <div className="vFormCheckbox__label">
                                Ich bin damit einverstanden, dass meine personenbezogenen Daten für Werbezwecke verarbeitet werden und eine
                                werbliche Ansprache per E-Mail erfolgt. Die erteilte Einwilligung kann ich jederzeit mit Wirkung für die Zukunft
                                in jeder angemessenen Form widerrufen.
                              </div>
                            </label>
                          </div>
                          <div className="rmBase__compError"></div>
                        </div>
                      </div>
                      <div className="rmBase__section">
                        <div className="rmBase__el rmBase__el--cta">
                          <button onClick={onClick} type="submit" className="rmBase__comp--cta">
                            Anmelden
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div data-page-type="pageSubscribeSuccess" className="rmBase__body rmSubscription hidden">
                  <div className="rmBase__content">
                    <div className="rmBase__container">
                      <div className="rmBase__section">
                        <div className="rmBase__el rmBase__el--text">
                          <div className="rmBase__comp--text">
                            Vielen Dank für Deine Anmeldung!
                            <br/>
                            <br/>
                            Eine E-Mail mit einem Aktivierungslink wurde an Deine E-Mail-Adresse geschickt - bitte prüfe auch Deinen
                            SPAM-Ordner.
                            { /* this linebreak is important, don't remove it! this will force trailing linebreaks to be displayed  */}
                            <br/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.space} />
          <div className={styles.social}>
            <div className={styles.singlebadge}>
              <div className={styles.text}><a href="https://twitter.com/taprolabsde" target="_blank">Twitter</a></div>
              <div className={styles.icon}><a href="https://twitter.com/taprolabsde" target="_blank"><img src={TwitterIcon} alt=""/></a></div>
            </div>
            <div className={styles.singlebadge}>
              <div className={styles.text}><a href="https://www.youtube.com/channel/UC2K1Rpn-RqHuraCKFAkJeuA" target="_blank">YouTube</a></div>
              <div className={styles.icon}><a href="https://www.youtube.com/channel/UC2K1Rpn-RqHuraCKFAkJeuA" target="_blank"><img src={YoutubeIcon}
                                                                                                                                   alt=""/></a>
              </div>
            </div>
          </div>

        </div>
      </div>
  );
}