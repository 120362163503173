import * as React from "react";
import DefaultPageContainer from "../components/DefaultPageContainer";
import DefaultPageContent from "../components/DefaultPageContent";
import useScrollToTop from "../modules/helper/useScrollToTop";
import { DataPrivacyConsumer } from "../modules/dataprivacy/DataPrivacyContext";
import type { DataPrivacyContextProps } from "../modules/dataprivacy/DataPrivacyContext";
import Helmet from "react-helmet";

export default function Datenschutz() {
  useScrollToTop();
  return (
    <DefaultPageContainer>
      <Helmet>
        <title>
          Datenschutz | TechAtom – Die Programmiersprache zum Anfassen
        </title>
        <meta name="description" content="" />
        <meta
          property="og:title"
          content="Datenschutz | TechAtom – Die Programmiersprache zum Anfassen"
        />
        <meta property="og:description" content="" />
      </Helmet>
      <DefaultPageContent>
        <h1>Datenschutz</h1>
        <p>
          der tapro labs GmbH, Kisdorfer Str. 14, 24641 Sievershütten
        </p>

        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick
          darüber, was mit Ihren personenbezogenen Daten passiert,
          wenn Sie diese Website besuchen. Personenbezogene Daten sind
          alle Daten, mit denen Sie persönlich identifiziert werden
          können. Ausführliche Informationen zum Thema Datenschutz
          entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>

        <h3>Datenerfassung auf dieser Website</h3>
        <p>
          Wer ist verantwortlich für die Datenerfassung auf dieser
          Website?
        </p>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem
          Impressum dieser Website entnehmen.
        </p>

        <h3>Wie erfassen wir Ihre Daten?</h3>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns
          diese mitteilen. Hierbei kann es sich z. B. um Daten
          handeln, die Sie in ein Kontaktformular eingeben.
        </p>
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung
          beim Besuch der Website durch unsere IT-Systeme erfasst. Das
          sind vor allem technische Daten (z. B. Internetbrowser,
          Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
          Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
          Website betreten.
        </p>

        <h3>Wofür nutzen wir Ihre Daten?</h3>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie
          Bereitstellung der Website zu gewährleisten. Andere Daten
          können zur Analyse Ihres Nutzerverhaltens verwendet werden.
        </p>

        <h3>Welche Rechte haben Sie bezüglich Ihrer Daten?</h3>
        <p>
          Sie haben jederzeit das Recht unentgeltlich Auskunft über
          Herkunft, Empfänger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben außerdem ein
          Recht, die Berichtigung oder Löschung dieser Daten zu
          verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung
          erteilt haben, können Sie diese Einwilligung jederzeit für
          die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
          bestimmten Umständen die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen. Des Weiteren
          steht Ihnen ein Beschwerderecht bei der zuständigen
          Aufsichtsbehörde zu.
        </p>
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können
          Sie sich jederzeit unter der im Impressum angegebenen
          Adresse an uns wenden.
        </p>

        <h3>Analyse-Tools und Tools von Drittanbietern</h3>
        <p>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten
          statistisch ausgewertet werden. Das geschieht vor allem mit
          Cookies und mit sogenannten Analyseprogrammen.
        </p>
        <p>
          Detaillierte Informationen zu diesen Analyseprogrammen
          finden Sie in der folgenden Datenschutzerklärung.
        </p>

        <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
        <h3>Externes Hosting</h3>
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet
          (Hoster). Die personenbezogenen Daten, die auf dieser
          Website erfasst werden, werden auf den Servern des Hosters
          gespeichert. Hierbei kann es sich v.a. um IP-Adressen,
          Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und
          sonstige Daten, die über eine Website generiert werden,
          handeln.
        </p>
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der
          Vertragserfüllung gegenüber unseren potenziellen und
          bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im
          Interesse einer sicheren, schnellen und effizienten
          Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie
          dies zur Erfüllung seiner Leistungspflichten erforderlich
          ist und unsere Weisungen in Bezug auf diese Daten befolgen.{" "}
        </p>
        <p>Abschluss eines Vertrages über Auftragsverarbeitung </p>
        <p>
          Um die datenschutzkonforme Verarbeitung zu gewährleisten,
          haben wir einen Vertrag über Auftragsverarbeitung mit
          unserem Hoster geschlossen.
        </p>

        <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
        <h3>Datenschutz</h3>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer
          persönlichen Daten sehr ernst. Wir behandeln Ihre
          personenbezogenen Daten vertraulich und entsprechend der
          gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene
          personenbezogene Daten erhoben. Personenbezogene Daten sind
          Daten, mit denen Sie persönlich identifiziert werden können.
          Die vorliegende Datenschutzerklärung erläutert, welche Daten
          wir erheben und wofür wir sie nutzen. Sie erläutert auch,
          wie und zu welchem Zweck das geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet
          (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken
          aufweisen kann. Ein lückenloser Schutz der Daten vor dem
          Zugriff durch Dritte ist nicht möglich.
        </p>

        <h3>Hinweis zur verantwortlichen Stelle</h3>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf
          dieser Website ist:
        </p>
        <p>
          tapro labs GmbH <br />
          Kisdorfer Str. 14
          <br />
          24641 Sievershütten
        </p>
        <p>
          E-Mail:{" "}
          <a href="mailto:kontakt@taprolabs.de">
            kontakt@taprolabs.de
          </a>
        </p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische
          Person, die allein oder gemeinsam mit anderen über die
          Zwecke und Mittel der Verarbeitung von personenbezogenen
          Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
        </p>

        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
          ausdrücklichen Einwilligung möglich. Sie können eine bereits
          erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
          formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
          der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
          Widerruf unberührt.
        </p>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen
          Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
        </h3>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1
          LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT,
          AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION
          ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
          DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF
          DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
          RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
          ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
          WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
          PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN,
          WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
          FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
          GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
          RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
          DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
          JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
          BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
          WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
          ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
          ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG
          VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        </p>

        <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen
          ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere
          in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
          Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu.
          Das Beschwerderecht besteht unbeschadet anderweitiger
          verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>

        <h3>Recht auf Datenübertragbarkeit</h3>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an einen Dritten in einem
          gängigen, maschinenlesbaren Format aushändigen zu lassen.
          Sofern Sie die direkte Übertragung der Daten an einen
          anderen Verantwortlichen verlangen, erfolgt dies nur, soweit
          es technisch machbar ist.
        </p>

        <h3>SSL- bzw. TLS-Verschlüsselung</h3>
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel
          Bestellungen oder Anfragen, die Sie an uns als
          Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung.
          Eine verschlüsselte Verbindung erkennen Sie daran, dass die
          Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können
          die Daten, die Sie an uns übermitteln, nicht von Dritten
          mitgelesen werden.
        </p>

        <h3>Auskunft, Löschung und Berichtigung</h3>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
          jederzeit das Recht auf unentgeltliche Auskunft über Ihre
          gespeicherten personenbezogenen Daten, deren Herkunft und
          Empfänger und den Zweck der Datenverarbeitung und ggf. ein
          Recht auf Berichtigung oder Löschung dieser Daten. Hierzu
          sowie zu weiteren Fragen zum Thema personenbezogene Daten
          können Sie sich jederzeit unter der im Impressum angegebenen
          Adresse an uns wenden.
        </p>

        <h3>Recht auf Einschränkung der Verarbeitung</h3>
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen. Hierzu können
          Sie sich jederzeit unter der im Impressum angegebenen
          Adresse an uns wenden. Das Recht auf Einschränkung der
          Verarbeitung besteht in folgenden Fällen:
        </p>
        <p>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
          personenbezogenen Daten bestreiten, benötigen wir in der
          Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung
          haben Sie das Recht, die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen.
        </p>
        <p>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten
          unrechtmäßig geschah/geschieht, können Sie statt der
          Löschung die Einschränkung der Datenverarbeitung verlangen.
        </p>
        <p>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen,
          Sie sie jedoch zur Ausübung, Verteidigung oder
          Geltendmachung von Rechtsansprüchen benötigen, haben Sie das
          Recht, statt der Löschung die Einschränkung der Verarbeitung
          Ihrer personenbezogenen Daten zu verlangen.
        </p>
        <p>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
          eingelegt haben, muss eine Abwägung zwischen Ihren und
          unseren Interessen vorgenommen werden. Solange noch nicht
          feststeht, wessen Interessen überwiegen, haben Sie das
          Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </p>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
          eingeschränkt haben, dürfen diese Daten – von ihrer
          Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines
          wichtigen öffentlichen Interesses der Europäischen Union
          oder eines Mitgliedstaats verarbeitet werden.
        </p>

        <h3>Widerspruch gegen Werbe-E-Mails</h3>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht
          veröffentlichten Kontaktdaten zur Übersendung von nicht
          ausdrücklich angeforderter Werbung und
          Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche
          Schritte im Falle der unverlangten Zusendung von
          Werbeinformationen, etwa durch Spam-E-Mails, vor.
        </p>

        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Cookies</h3>
        <p>
          Unsere Internetseiten verwenden so genannte „Cookies“.
          Cookies sind kleine Textdateien und richten auf Ihrem
          Endgerät keinen Schaden an. Sie werden entweder
          vorübergehend für die Dauer einer Sitzung (Session-Cookies)
          oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
          gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
          automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
          Endgerät gespeichert bis Sie diese selbst löschen oder eine
          automatische Lösung durch Ihren Webbrowser erfolgt.
        </p>
        <p>
          Teilweise können auch Cookies von Drittunternehmen auf Ihrem
          Endgerät gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
          Nutzung bestimmter Dienstleistungen des Drittunternehmens
          (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
        </p>
        <p>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies
          sind technisch notwendig, da bestimmte Webseitenfunktionen
          ohne diese nicht funktionieren würden (z.B. die
          Warenkorbfunktion oder die Anzeige von Videos). Andere
          Cookies dienen dazu das Nutzerverhalten auszuwerten oder
          Werbung anzuzeigen.
        </p>
        <p>
          Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs (notwendige Cookies) oder zur
          Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
          (funktionale Cookies, z. B. für die Warenkorbfunktion) oder
          zur Optimierung der Webseite (z.B. Cookies zur Messung des
          Webpublikums) erforderlich sind, werden auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
          Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
          berechtigtes Interesse an der Speicherung von Cookies zur
          technisch fehlerfreien und optimierten Bereitstellung seiner
          Dienste. Sofern eine Einwilligung zur Speicherung von
          Cookies abgefragt wurde, erfolgt die Speicherung der
          betreffenden Cookies ausschließlich auf Grundlage dieser
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung
          ist jederzeit widerrufbar.
        </p>
        <p>
          Sie können Ihren Browser so einstellen, dass Sie über das
          Setzen von Cookies informiert werden und Cookies nur im
          Einzelfall erlauben, die Annahme von Cookies für bestimmte
          Fälle oder generell ausschließen sowie das automatische
          Löschen der Cookies beim Schließen des Browsers aktivieren.
          Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
        </p>
        <p>
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken
          eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
          Datenschutzerklärung gesondert informieren und ggf. eine
          Einwilligung abfragen.
        </p>
        <p>
          Möchten Sie Ihre Cookie-Einstellungen für unsere Wesbite
          anpassen, dann klicken Sie bitte hier:&nbsp;
          <DataPrivacyConsumer>
            {(dataPrivacy: DataPrivacyContextProps) => (
              <a onClick={() => dataPrivacy.showSettings()}>
                Dateneinstellungen
              </a>
            )}
          </DataPrivacyConsumer>
          .
        </p>

        <h3>Server-Log-Dateien</h3>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch
          Informationen in so genannten Server-Log-Dateien, die Ihr
          Browser automatisch an uns übermittelt. Dies sind:
        </p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse</li>
        </ul>

        <p>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen
          wird nicht vorgenommen.
        </p>
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
          berechtigtes Interesse an der technisch fehlerfreien
          Darstellung und der Optimierung seiner Website – hierzu
          müssen die Server-Log-Files erfasst werden.
        </p>

        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren,
          wird Ihre Anfrage inklusive aller daraus hervorgehenden
          personenbezogenen Daten (Name, Anfrage) zum Zwecke der
          Bearbeitung Ihres Anliegens bei uns gespeichert und
          verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung
          eines Vertrags zusammenhängt oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich ist. In allen
          übrigen Fällen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder
          auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern
          diese abgefragt wurde.
        </p>
        <p>
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten
          verbleiben bei uns, bis Sie uns zur Löschung auffordern,
          Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
          für die Datenspeicherung entfällt (z. B. nach
          abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
          gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.
        </p>

        <h2>5. Newsletter</h2>
        <h3>Newsletterdaten</h3>
        <p>
          Um den auf unserer Webseite angebotenen Newsletter zu
          beziehen, können Sie sich über unser Formular anmelden.
          Dabei nutzen wir das sogenannte Double-Opt-In Verfahren.
          Hier wird zunächst eine Bestätigungsmail an Ihre angegebene
          E-Mail Adresse gesendet, mit der Bitte um Bestätigung. Die
          Anmeldung wird erst wirksam, wenn Sie den in der
          Bestätigungsmail enthaltenen Aktivierungslink anklicken. Wir
          verwenden Ihre an uns übertragenen Daten ausschließlich für
          den Versand des Newsletters, der Informationen oder Angebot
          enthalten kann.
        </p>
        <p>
          Wir verwenden rapidmail, um unseren Newsletter zu versenden.
          Ihre Daten werden daher an die rapidmail GmbH übermittelt.
          Dabei ist es der rapidmail GmbH untersagt, Ihre Daten für
          andere Zwecke als für den Versand des Newsletters zu nutzen.
          Eine Weitergabe oder ein Verkauf Ihrer Daten ist der
          rapidmail GmbH nicht gestattet. rapidmail ist ein deutscher,
          zertifizierter Newsletter Software Anbieter, welcher nach
          den Anforderungen der DSGVO und des BDSG sorgfältig
          ausgewählt wurde.
        </p>
        <p>
          Sie können Ihre Einwilligung zur Speicherung der Daten und
          deren Nutzung zum Newsletter-Versand jederzeit widerrufen,
          z.B. über den Abmelde-Link im Newsletter.
        </p>

        <h2>6. Plugins und Tools</h2>
        <h3>Vimeo</h3>
        <p>
          Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter
          ist die Vimeo Inc., 555 West 18th Street, New York, New York
          10011, USA.
        </p>
        <p>
          Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten
          Seiten besuchen, wird eine Verbindung zu den Servern von
          Vimeo hergestellt. Dabei wird dem Vimeo-Server mitgeteilt,
          welche unserer Seiten Sie besucht haben. Zudem erlangt Vimeo
          Ihre IP-Adresse. Dies gilt auch dann, wenn Sie nicht bei
          Vimeo eingeloggt sind oder keinen Account bei Vimeo
          besitzen. Die von Vimeo erfassten Informationen werden an
          den Vimeo-Server in den USA übermittelt.
        </p>
        <p>
          Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen
          Sie Vimeo, Ihr Surfverhalten direkt Ihrem persönlichen
          Profil zuzuordnen. Dies können Sie verhindern, indem Sie
          sich aus Ihrem Vimeo-Account ausloggen.
        </p>
        <p>
          Zur Wiedererkennung der Websitebesucher verwendet Vimeo
          Cookies bzw. vergleichbare Wiedererkennungstechnologien
          (z.B. Device-Fingerprinting).
        </p>
        <p>
          Die Nutzung von Vimeo erfolgt im Interesse einer
          ansprechenden Darstellung unserer Online-Angebote. Dies
          stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1
          lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
          abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
          Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung
          ist jederzeit widerrufbar.
        </p>
        <p>
          Die Datenübertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission sowie nach
          Aussage von Vimeo auf „berechtigte Geschäftsinteressen“
          gestützt. Details finden Sie hier:&nbsp;
          <a href="https://vimeo.com/privacy" target="_blank">
            https://vimeo.com/privacy
          </a>
          .
        </p>
        <p>
          Weitere Informationen zum Umgang mit Nutzerdaten finden Sie
          in der Datenschutzerklärung von Vimeo unter:&nbsp;
          <a href="https://vimeo.com/privacy" target="_blank">
            https://vimeo.com/privacy
          </a>
          .
        </p>

        <h3>Google Web Fonts</h3>
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von
          Schriftarten so genannte Web Fonts, die von Google
          bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr
          Browser die benötigten Web Fonts in ihren Browsercache, um
          Texte und Schriftarten korrekt anzuzeigen.
        </p>
        <p>
          Zu diesem Zweck muss der von Ihnen verwendete Browser
          Verbindung zu den Servern von Google aufnehmen. Hierdurch
          erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse
          diese Website aufgerufen wurde. Die Nutzung von Google
          WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO. Der Webseitenbetreiber hat ein berechtigtes Interesse
          an der einheitlichen Darstellung des Schriftbildes auf
          seiner Webseite. Sofern eine entsprechende Einwilligung
          abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
          Cookies), erfolgt die Verarbeitung ausschließlich auf
          Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung
          ist jederzeit widerrufbar.
        </p>
        <p>
          Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
          Standardschrift von Ihrem Computer genutzt.
        </p>
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
          >
            https://developers.google.com/fonts/faq
          </a>{" "}
          und in der Datenschutzerklärung von Google:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Google Analytics</h3>
        <p>
          Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden
          Optimierung unserer Seiten nutzen wir auf Grundlage des
          Artikel 6 Absatz 1 Buchstabe f) DSGVO Google Analytics,
          einen Webanalysedienst der Google Inc. („Google“). Google
          Analytics verwendet sog. „Cookies“, Textdateien, die auf
          Deinem Computer gespeichert werden und die eine Analyse der
          Benutzung der Website durch Dich ermöglichen. In diesem
          Zusammenhang werden pseudonymisierte Nutzungsprofile
          erstellt und Cookies verwendet. Die durch das Cookie
          erzeugten Informationen über Deine Benutzung dieser Website
          wie
        </p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse</li>
        </ul>
        <p>
          Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Deine Nutzung der Website
          auszuwerten, um Reports über die Websiteaktivitäten
          zusammenzustellen und um weitere mit der Websitenutzung und
          der Internetnutzung verbundene Dienstleistungen gegenüber
          dem Websitebetreiber zu erbringen. Die im Rahmen von Google
          Analytics von Deinem Browser übermittelte IP-Adresse wird
          nicht mit anderen Daten von Google zusammengeführt. Du
          kannst die Speicherung der Cookies durch eine entsprechende
          Einstellung Deiner Browser-Software verhindern; wir weisen
          Dich jedoch darauf hin, dass Du in diesem Fall
          gegebenenfalls nicht sämtliche Funktionen dieser Website
          vollumfänglich wirst nutzen können. Du kannst darüber hinaus
          die Erfassung der durch das Cookie erzeugten und auf Deine
          Nutzung der Website bezogenen Daten (inkl. Deiner
          IP-Adresse) an Google sowie die Verarbeitung dieser Daten
          durch Google verhindern, indem Du das unter dem folgenden
          Link verfügbare Browser-Plugin herunterlädst und
          installierst:{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
          >
            tools.google.com/dlpage/gaoptout?hl=de
          </a>
        </p>
      </DefaultPageContent>
    </DefaultPageContainer>
  );
}
