import * as React from 'react';
import Wording_64px_height from '../assets/images/Wording_64px_height.png';
import {Link, withRouter} from 'react-router-dom';
import './TopMenu.scss';
import {useIsPhone} from '../TechAtomHooks';
import {slide as Menu} from 'react-burger-menu';
import {Close, Menu as MenuIcon} from '@material-ui/icons';
import Icon_512px from '../assets/images/Icon_512px.png';
import {useContext, useState} from 'react';
import ParallaxHelper from '../modules/helper/ParallaxHelper';
import TrackedHashLink from '../modules/tracking/TrackedHashLink';
import type {GoogleAnalyticsContextProps} from '../modules/tracking/GoogleAnalyticsContext';
import GoogleAnalyticsContext from '../modules/tracking/GoogleAnalyticsContext';

function TopMenu(props) {
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  const path = props.location.pathname;
  const hash = props.location.hash;
  const whiteItems = props.whiteItems || false;
  const isPhone = useIsPhone();

  const [menuOpened, setMenuOpened] = useState(false);

  const scrolledUp = ParallaxHelper.useIsScrolledUp(80);

  function onMenuOpened() {
    setMenuOpened(true);
    googleAnalytics.event('modal_open', {
      identifier: 'offsite-menu'
    });
  }

  function onMenuClosed() {
    setMenuOpened(false);
    googleAnalytics.event('modal_closed', {
      identifier: 'offsite-menu'
    });
  }

  function gotoDataSection(e) {
    setMenuOpened(false);
    if (document.location.path != '/') {
      window.location.href = '/#ausprobieren';
    }
    const element = document.getElementById('ausprobieren');
    let offset = isPhone ? 500 : 800;
    const leftoverOffset = 0;
    window.scrollTo({top: element.offsetTop - offset - leftoverOffset});
    const interval = window.setInterval(() => {
      offset *= 0.95;
      window.scrollTo({top: element.offsetTop - offset - leftoverOffset});
      if (offset <= 20) {
        clearInterval(interval);
      }
    }, 10);
  }

  const items = [
    <li key="entdecken" className={path === '/' && (hash === '' || hash === '#' || hash === '#entdecken') ? 'active' : ''}>
      <TrackedHashLink trackingData={{section: 'top-menu'}} onClick={() => setMenuOpened(false)} to="/#entdecken">Entdecken</TrackedHashLink>
    </li>,
    <li key="videos" className={path === '/' && (hash === '#videos') ? 'active' : ''}>
      <TrackedHashLink trackingData={{section: 'top-menu'}} onClick={() => setMenuOpened(false)} to="/#videos">Videos</TrackedHashLink>
    </li>,
    <li key="ausprobieren" className={path === '/' && hash === '#ausprobieren' ? 'active' : ''}>
      <TrackedHashLink trackingData={{section: 'top-menu', to: '/#ausprobieren'}} onClick={gotoDataSection}>Spielen</TrackedHashLink>
    </li>,
    <li key="kontakt" className={path === '/' && hash === '#kontakt' ? 'active' : ''}>
      <TrackedHashLink trackingData={{section: 'top-menu'}} onClick={() => setMenuOpened(false)} to="/#kontakt">Kontakt</TrackedHashLink>
    </li>,
    <li key="separator" className={'separator'}>
      |
    </li>,
    <li key="shop" className={path === '/shop' ? 'active' : ''}>
      <TrackedHashLink trackingData={{section: 'top-menu'}} onClick={() => setMenuOpened(false)} to="/shop">Shop</TrackedHashLink>
    </li>
  ];
  return (
      <div
          className={'TopMenu' + (whiteItems ? ' white-items' : '')}
          style={{'--menu-button-top': scrolledUp ? '0px' : '-100px'}}
      >
        {isPhone &&
         <Menu isOpen={menuOpened} onOpen={onMenuOpened} onClose={onMenuClosed} right outerContainerId={'app'} customBurgerIcon={<MenuIcon/>}
               customCrossIcon={<Close fontSize="large"/>}>
           <div className="top-logo">
             <TrackedHashLink trackingData={{section: 'top-menu', label: 'text-logo'}} onClick={() => setMenuOpened(false)} to="/#"><img
                 src={Wording_64px_height} alt="Logo"/></TrackedHashLink>
           </div>
           <ul>{items.filter(jsx => jsx.key !== 'separator')}</ul>
           <div className="bottom-logo">
             <TrackedHashLink trackingData={{section: 'top-menu', label: 'logo'}} onClick={() => setMenuOpened(false)} to="/#">
               <img src={Icon_512px} alt="Logo Icon"/>
             </TrackedHashLink>
           </div>
         </Menu>
        }
        <div className={'menu-container'}>

          <div className="logo">
            <TrackedHashLink trackingData={{section: 'top-menu', label: 'text-logo'}} to="/#"><img src={Wording_64px_height}
                                                                                                   alt="Logo"/></TrackedHashLink>
          </div>
          {!isPhone &&
           <nav>
             <ul>{items}</ul>
           </nav>
          }
        </div>
      </div>
  );
}

export default withRouter(TopMenu);