import type {GoogleAnalyticsContextProps} from './GoogleAnalyticsContext';
import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import GoogleAnalyticsContext from './GoogleAnalyticsContext';

export default function useSectionViewTracker(section, ref, config) {
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  const [focusVisible, setFocusVisible] = useState(isFocusVisible(ref));
  const [tracktimer, setTracktimer] = useState([]);
  const [viewed, setViewed] = useState(false);
  const [stayed, setStayed] = useState(false);

  function isFocusVisible(ref) {
    const elementRect = ref && ref.current && ref.current.getBoundingClientRect();
    if (elementRect) {
      const maxVisible = elementRect.height > window.innerHeight ? window.innerHeight : elementRect.height;
      if (elementRect.top <= 0 && elementRect.bottom >= window.innerHeight) {
        // console.log(section, 'screen filling section');
        return true;
      } else if (elementRect.top >= 0 && elementRect.bottom <= window.innerHeight) {
        // console.log(section, 'section completely visible');
        return true;
      } else if (elementRect.top < 0 && elementRect.bottom >= 0 && elementRect.bottom <= window.innerHeight) {
        const elementVisibility = elementRect.bottom / maxVisible;
        const windowVisibility = elementRect.bottom / window.innerHeight;
        // console.log(section, 'leaving top', 'elementVisibility', elementVisibility, ' windowVisibility', windowVisibility);
        return (windowVisibility > 0.7 || elementVisibility > 0.8);
      } else if (elementRect.bottom > window.innerHeight && elementRect.top <= window.innerHeight && elementRect.top >= 0) {
        const elementVisibility = (window.innerHeight - elementRect.top) / maxVisible;
        const windowVisibility = (window.innerHeight - elementRect.top) / window.innerHeight;
        // console.log(section, 'leaving bottom', 'elementVisibility', elementVisibility, ' windowVisibility', windowVisibility);
        return (windowVisibility > 0.5 || elementVisibility > 0.8);
      }
    }
    return false;
  }

  useEffect(() => {
    if (focusVisible) {
      setTracktimer([
        setTimeout(() => {
          if (!viewed) {
            googleAnalytics.event('section_viewed', {
              ...googleAnalytics.identifierParams(section),
              timeout: 3000,
              elapsed_total: performance.now()
            });
          } else {
            console.log('section', section, 'was already viewed');
          }
          setViewed(true);
        }, 3000),
        setTimeout(() => {
          if (!stayed) {
            googleAnalytics.event('section_stayed', {
              ...googleAnalytics.identifierParams(section),
              timeout: 30000,
              elapsed_total: performance.now()
            });
          } else {
            console.log('section', section, 'was already stayed');
          }
          setStayed(true);
        }, 30000)
      ]);
    } else {
      tracktimer.forEach(timer => clearTimeout(timer));
      setTracktimer([]);
    }
    // console.log(section, 'isFocusVisible changed', focusVisible);
  }, [focusVisible]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const newFocusVisible = isFocusVisible(ref);
      setFocusVisible(newFocusVisible);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
}
