import type {GoogleAnalyticsContextProps} from './GoogleAnalyticsContext';
import {useContext, useEffect, useState} from 'react';
import GoogleAnalyticsContext from './GoogleAnalyticsContext';

export default function useHoverSectionTracker(section: string, hoverItemKeys: [string]) {
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);

  const [hoveredItems, setHoveredItems] = useState({});
  const [allItemsDone, setAllItemsDone] = useState(false);

  useEffect(() => {
    if (!allItemsDone && hoverItemKeys.every(item => !!hoveredItems[item])) {
      googleAnalytics.event('hover_section_all', {
        ...googleAnalytics.identifierParams(section),
        items: hoverItemKeys.join(', ')
      });
      setAllItemsDone(true);
    }
  }, [allItemsDone, hoveredItems]);

  function onClick(key) {
    googleAnalytics.event('hover_click', {
      ...googleAnalytics.identifierParams(section, key)
    });
  }

  function onMouseEnter(key) {
    if (Object.keys(hoveredItems).length == 0) {
      googleAnalytics.event('hover_section_first_item', {
        ...googleAnalytics.identifierParams(section, key)
      });
    }
    setHoveredItems(items => {
      return {
        ...items,
        [key]: true
      };
    });
  }

  return {
    setupClick: (key) => {
      return () => {
        onClick(key);
      }
    },
    setupMouseEnter: (key) => {
      return () => {
        onMouseEnter(key);
      }
    }
  }
}
