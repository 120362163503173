import * as React from 'react';
import styles from './VideoBackground.module.scss';

export default class VideoBackground extends React.Component {

  render() {
    return (
        <div className={styles.VideoBackground}>
          <iframe frameBorder="0"  allow="autoplay; fullscreen"
                  src={"https://player.vimeo.com/video/"+this.props.vimeoVideoId+"?background=1&muted=1"}>
          </iframe>
          {this.props.children}
        </div>
  );
  }
  }