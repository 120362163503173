import * as React from 'react';
import {Link} from 'react-router-dom';
import styles from './BigActionButton.module.scss';

export default class BigActionButton extends React.Component {
  
  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const className = styles.BigActionButton + (this.props.darkBackground ? ' '+styles.darkBackground : '');
    if (this.props.toLink && !this.props.externalLink) {
      return <Link className={className}  to={this.props.toLink}>{this.props.title }</Link>;
    }
    if (this.props.toLink && this.props.externalLink) {
      return <a className={className}  href={this.props.toLink} target="_blank">{this.props.title }</a>
    }
    return (
        <span className={className} onClick={this.onClick.bind(this)}>
          {this.props.title}
        </span>
    );
  }
}