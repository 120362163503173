import * as React from 'react';
import Icon_512px from '../assets/images/Icon_512px.png';
import './Footer.scss';
import { DataPrivacyConsumer } from '../modules/dataprivacy/DataPrivacyContext';
import type { DataPrivacyContextProps } from '../modules/dataprivacy/DataPrivacyContext';
import TrackedHashLink from '../modules/tracking/TrackedHashLink';

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="PageFooter">
        <div className="footer-container">
          <div className="left-logo">
            <TrackedHashLink
              trackingData={{ section: 'footer', label: 'logo' }}
              to="/#"
            >
              <img src={Icon_512px} alt="Logo Icon" />
            </TrackedHashLink>
          </div>
          <div className="column">
            <h3>TechAtom</h3>
            <ul>
              <li>
                <TrackedHashLink
                  trackingData={{ section: 'footer' }}
                  to="/#entdecken"
                >
                  Entdecken
                </TrackedHashLink>
              </li>
              <li>
                <TrackedHashLink
                  trackingData={{ section: 'footer' }}
                  to="/#videos"
                >
                  Videos
                </TrackedHashLink>
              </li>
              <li>
                <TrackedHashLink
                  trackingData={{ section: 'footer' }}
                  to="/#ausprobieren"
                >
                  Spielen
                </TrackedHashLink>
              </li>
              <li>
                <TrackedHashLink
                  trackingData={{ section: 'footer' }}
                  to="/#kontakt"
                >
                  Kontakt
                </TrackedHashLink>
              </li>
              <li>
                <TrackedHashLink
                  trackingData={{ section: 'footer' }}
                  to="/shop"
                >
                  Shop
                </TrackedHashLink>
              </li>
            </ul>
          </div>
          <div className="column">
            <h3>Legal</h3>
            <ul>
              <li>
                <TrackedHashLink
                  trackingData={{ section: 'footer' }}
                  to="/impressum"
                >
                  Impressum
                </TrackedHashLink>
              </li>
              <li>
                <TrackedHashLink
                  trackingData={{ section: 'footer' }}
                  to="/datenschutz"
                >
                  Datenschutz
                </TrackedHashLink>
              </li>
              <li>
                <DataPrivacyConsumer>
                  {(dataPrivacy: DataPrivacyContextProps) => (
                    <a onClick={() => dataPrivacy.showSettings()}>
                      Dateneinstellungen
                    </a>
                  )}
                </DataPrivacyConsumer>
              </li>
            </ul>
          </div>
          <div className="column">
            <h3>Kontakt</h3>
            <p>
              Am einfachsten erreichst Du uns per E-Mail. Schreib uns
              unter: <br />
              <a href="mailto:kontakt@techatom.de">
                kontakt@techatom.de
              </a>
            </p>
            ​​<p>Wir freuen uns auf Deine Nachricht!</p>
          </div>
        </div>
        <div className="mobile-logo">
          <TrackedHashLink
            trackingData={{ section: 'footer', label: 'mobile-logo' }}
            to="/#"
          >
            <img src={Icon_512px} alt="Logo Icon" />
          </TrackedHashLink>
        </div>
      </footer>
    );
  }
}
