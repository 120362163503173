import * as React from 'react';
import SectionHeadline from '../components/SectionHeadline';
import EduExplainCard from './EduExplainCard';
import PuzzleIcon from '../../../assets/Bilder/Bilder Lernsection/Puzzleicon/puzzle-icon.png';
import TechAtomBinaryIcon from '../../../assets/Bilder/Display_01_Animation/animation.gif';
import WrenchIcon from '../../../assets/Bilder/Schraubschluessel.png';
import AtomOKIcon from '../../../assets/Bilder/test.png';
import DefaultContentWrapper from '../../../components/DefaultContentWrapper';
import styles from './EducationExplainSection.module.scss';
import { useIsPhone } from '../../../TechAtomHooks';

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import type { GoogleAnalyticsContextProps } from '../../../modules/tracking/GoogleAnalyticsContext';
import { useContext, useRef } from 'react';
import GoogleAnalyticsContext from '../../../modules/tracking/GoogleAnalyticsContext';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function EducationExplainSection() {
  const isPhone = useIsPhone();
  const cards = [
    <EduExplainCard
      key="experiment"
      icon={PuzzleIcon}
      textLeft={false}
    >
      Experimentiere mit den Konzepten moderner Programmierung:{' '}
      <strong>
        Funktionen, Parameter, Debugging, Inputs, Outputs,
        if/else-Anweisungen, Arrays
      </strong>{' '}
      und viele mehr warten nur darauf, von Dir entdeckt zu werden!
    </EduExplainCard>,
    <EduExplainCard
      key="concept"
      icon={TechAtomBinaryIcon}
      textLeft={true}
    >
      Durch das Konzept hinter TechAtom lernst Du grundlegende Dinge
      über Daten: Was Daten sind, welche Daten es gibt, wie Daten
      fließen und wie Daten manipuliert werden können. So erlebst Du,
      wie Programmierung im Kern funktioniert - denn Programmierung
      ist Datenverarbeitung.
    </EduExplainCard>,
    <EduExplainCard
      key="compthink"
      icon={WrenchIcon}
      textLeft={false}
    >
      <strong>Computational Thinking</strong> bedeutet einfach
      ausgedrückt, sich ein Problem in Gedanken so zurecht zu legen,
      dass es theoretisch auch von einem Computer gelöst werden
      könnte. Ein wichtiges Werkzeug, das viele Menschen bereits im
      Alltag nutzen und das auch von Programmierer*innen bewusst
      eingesetzt wird. Genau diese Denkweise wird durch das Konzept
      von TechAtom gefördert.
    </EduExplainCard>,
    <EduExplainCard key="play" icon={AtomOKIcon} textLeft={true}>
      Neben der Frage des <strong>"Was lerne ich"</strong> spielt bei
      TechAtom auch die Frage des "Wie" eine entscheidende Rolle: Wir
      sind davon überzeugt, dass der spielerische Ansatz und die
      Notwendigkeit, TechAtom buchstäblich mit den eigenen Händen zu
      erleben, zu größeren Lernerfolgen führt.
    </EduExplainCard>,
  ];

  const googleAnalytics: GoogleAnalyticsContextProps = useContext(
    GoogleAnalyticsContext
  );
  function onSlideChange(e) {
    googleAnalytics.event('slide', {
      ...googleAnalytics.identifierParams('main education', 'cards'),
      index_offset: e.previousIndex + ' -> ' + e.activeIndex,
    });
  }

  const mainDiv = useRef();
  useSectionViewTracker('main education', mainDiv, {});

  return (
    <div ref={mainDiv} className={styles.EducationExplainSection}>
      <div className={styles.sectionContainer}>
        <div className={styles.headline}>
          <SectionHeadline
            title="Was kann ich mit<br/> TechAtom entdecken?"
            darkBackground
          />
        </div>
        {isPhone && (
          <Swiper navigation onSlideChange={onSlideChange}>
            {cards.map((card, inx) => (
              <SwiperSlide key={'slide' + inx}>{card}</SwiperSlide>
            ))}
          </Swiper>
        )}
        {!isPhone && cards.map((card, inx) => card)}
      </div>
    </div>
  );
}
