// @flow
import atomStyles from './Atom.module.scss';
import * as React from 'react';
import {ReactNode} from 'react';

export type AtomProps = {
  x: ?number,
  y: ?number,
  rotation: ?number,
  scale: ?number,
  highlight: ?boolean,
  className: ?string,
  children: ?[ReactNode],
  style: ?Object
}

export function AtomImage(props) {
  return (
      <div 
          className={atomStyles.AtomImage + (props.className ? ' ' + props.className : '')}
          style={props.style}
      >
        {props.children}
      </div>
  );
}

export function AtomFrame(props: AtomProps) {
  const highlight = props.highlight || false;
  const x = props.x || 0;
  const y = props.y || 0;
  const rotation = props.rotation || 0;
  const scale = props.scale / 250 || 0.25;
  return (
      <div className={atomStyles.AtomFrame + ' ' + props.className + (highlight ? ' ' + atomStyles.highlight : '')}
           style={{
             '--atom-scale': scale,
             '--atom-top': y + 'px',
             '--atom-left': x + 'px',
             '--atom-rotation': rotation + 'deg',
             ...props.style
           }}>
        {props.children}
      </div>
  );
}

export default function Atom(styleClass, props: AtomProps, image) {
  const highlight = props.highlight || false;
  const x = props.x || 0;
  const y = props.y || 0;
  const rotation = props.rotation || 0;
  const scale = props.scale / 250 || 0.25;
  return (
      <div className={atomStyles.Atom + ' ' + styleClass + ' ' + props.className + (highlight ? ' ' + atomStyles.highlight : '')}
           style={{
             '--atom-scale': scale,
             '--atom-top': y + 'px',
             '--atom-left': x + 'px',
             '--atom-rotation': rotation + 'deg'
           }}>
      </div>
  );
}