import * as React from 'react';
import Atom, {AtomFrame, AtomImage} from './Atom';
import styles from './KeyboardAtom.module.scss';
import type {AtomProps} from './Atom';
import {useEffect, useState} from 'react';

type Props = {
  ...AtomProps,
  onValueChanged: ?(value) => void
};

let keys = [];
for (let i = 1; i <= 8; i++) {
  keys.push('key' + i);
}
const charKeys = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K'];

export default function KeyboardAtom(props: Props) {
  const [keysPressed, setKeysPressed] = useState({});
  function setKeyPressed(key, value) {
    setKeysPressed((state) => {
      let newPressed = {...state};
      newPressed[key] = value;
      return newPressed;
    });
  }

  useEffect(() => {
    if (props.onValueChanged) {
        props.onValueChanged(keys.map(key => !!keysPressed[key]));
      }
    // action on update of movies
  }, [keysPressed]);
  
  function downHandler(event) {
    if (event.repeat) {
      return;
    }
    const keyChar = String.fromCharCode(event.keyCode);
    const indexOf = charKeys.indexOf(keyChar);
    if (indexOf !== false) {
      setKeyPressed(keys[indexOf], true);
    }
  }
  
  function upHandler(event) {
    const keyChar = String.fromCharCode(event.keyCode);
    const indexOf = charKeys.indexOf(keyChar);
    if (indexOf !== false) {
      setKeyPressed(keys[indexOf], false);
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount


  return <AtomFrame className={styles.KeyboardAtom} {...props}>
    {keys.filter(key => keysPressed[key]).map(key => <AtomImage key={key} className={styles.keyon + ' ' + styles[key + 'on']}/>)}
    {keys.map(key => 
         <div key={'clickTarget_'+key} 
              className={styles.key + ' ' + styles[key]} 
              onMouseDown={() => setKeyPressed(key, true)}
              onMouseUp={() => setKeyPressed(key, false)}
              onTouchStart={() => setKeyPressed(key, true)}
              onTouchEnd={() => setKeyPressed(key, false)}
         />
    )}
  </AtomFrame>;
}