import * as React from 'react';
import styles from './ChatCarouselPage.module.scss';
import Logo from '../../../assets/images/Icon_256px.png';

export default class ChatCarouselPage extends React.Component {
  constructor() {
    super();
    this.state = {ellipsisRepeat: 0};
  }
  
  componentWillMount() {
    this.ellipsisTimer = setInterval(() => {
      const ellipsisRepeat = this.state.ellipsisRepeat;
      this.setState({ellipsisRepeat: (ellipsisRepeat + 1) % 4});
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.ellipsisTimer);
  }

  render() {
    const ellipsisRepeat = this.state.ellipsisRepeat;
    return (
        <div className={styles.ChatCarouselPage}>
          {this.props.children}
          {this.props.bigWaiting && 
            <div className={styles.bigTypingMessage}>
              <div className={styles.logo}>
                <img src={Logo}/>
              </div>
              <div className={styles.message}>
                schreibt<span>{'.'.repeat(ellipsisRepeat)}</span>
              </div>
            </div>
          }
          {!this.props.bigWaiting && !this.props.hideWaiting && 
            <div className={styles.typingMessage}>
              <div className={styles.logo}>
                <img src={Logo}/>
              </div>
              <div className={styles.message}>
                schreibt<span>{'.'.repeat(ellipsisRepeat)}</span>
              </div>
            </div>
          }
        </div>
    );
  }
}