import * as React from 'react';
import './ShopBackground.scss';
import ParallaxHelper from '../../modules/helper/ParallaxHelper';

export default function ShopBackground() {
  const scrollLength = -200;
  const scrollY = ParallaxHelper.useScrollY();
  const scrollPercentage = scrollY / document.body.clientHeight;
  const transform = 'translateY(' + (scrollPercentage * scrollLength) + 'px)';
  return (
      <div className="ShopBackground">
        <div className="scrolling-background" style={{transform: transform}}></div>
      </div>
  );
}
