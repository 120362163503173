import * as React from 'react';
import ChatCarousel from './ChatCarousel';
import ChatCarouselPage from './ChatCarouselPage';
import ChatCarouselMessage from './ChatCarouselMessage';
import ScrollIcon from './images/scroll-icon.gif';
import styles from './ChatIntroSection.module.scss';
import {HashLink} from 'react-router-hash-link';
import {useIsPhone} from '../../../TechAtomHooks';
import ParallaxHelper from '../../../modules/helper/ParallaxHelper';
import {useRef} from 'react';
import TrackedHashLink from '../../../modules/tracking/TrackedHashLink';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';

export default function ChatIntroSection() {
  const mainDiv = useRef();
  const contentDiv = useRef();
  let scrollTop = 1000;
  let scrollBottom = 3000;
  let manualTop = 3000;
  const scrollY = ParallaxHelper.useScrollY();
  if (mainDiv.current) {
    const rect = mainDiv.current.getBoundingClientRect();
    scrollTop = rect.top + scrollY;
    scrollBottom = rect.bottom + scrollY - window.innerHeight;
    manualTop = scrollY <= scrollTop ? 0 : rect.height - window.innerHeight;
  }
  const fixed = scrollY >= scrollTop && scrollY <= scrollBottom;
  let backgroundPositionY;
  if (fixed) {
    const sPer = (scrollY - scrollTop) / (scrollBottom - scrollTop);
    backgroundPositionY = -100 * sPer;
  } else if (scrollY < scrollTop) {
    backgroundPositionY = -0.5 * (scrollY - scrollTop);
  } else {
    backgroundPositionY = -0.5 * (scrollY - scrollBottom) - 100;
  }
  const isPhone = useIsPhone();
  if (isPhone) {
    backgroundPositionY -= 300;
  }
  const contentPercentage = (scrollY - scrollTop) / (scrollBottom - scrollTop);

  useSectionViewTracker('main chat', mainDiv, {});

  return (
      <div ref={mainDiv} className={styles.ChatIntroSection}>
        <div className={styles.fixableWrapper} style={{position: fixed ? 'fixed' : 'relative', top: fixed ? 0 : manualTop}}>
          <div className={styles.content}>
            <ChatCarousel scrollPercentage={contentPercentage} scrollDistances={[
              {inputDistance: 25, outputDistance: 0},
              {inputDistance: 10, outputDistance: 1},
              {inputDistance: 25, outputDistance: 0},
              {inputDistance: 10, outputDistance: 1},
              {inputDistance: 25, outputDistance: 0},
              {inputDistance: 10, outputDistance: 1},
              {inputDistance: 25, outputDistance: 0}
            ]}>
              <ChatCarouselPage bigWaiting></ChatCarouselPage>
              <ChatCarouselPage>
                <br/>
                <ChatCarouselMessage>TechAtome sind intelligente Bausteine, mit denen Du spielerisch in die Welt der Programmierung eintauchen 
                  kannst, ohne dafür vor einem Bildschirm sitzen zu müssen.</ChatCarouselMessage>
              </ChatCarouselPage>
              <ChatCarouselPage>
                <br/>
                <ChatCarouselMessage>Indem Du mit TechAtom experimentierst und die Bausteine auf unterschiedliche Art und Weise zusammen steckst,
                  kannst Du vielfältige und aufregende Anwendungen bauen.</ChatCarouselMessage>
                </ChatCarouselPage>
              <ChatCarouselPage hideWaiting>
                <br/>
                <ChatCarouselMessage>
                  Lass Deiner Kreativität und Deiner Neugier freien Lauf und zeig der Welt, was in Dir steckt! <br/><br/>
                  <strong>Worauf wartest Du?</strong>
                </ChatCarouselMessage>
                <ChatCarouselMessage responseMessage>
                    <span className={styles.ctaResponse}>
                      &gt;_ <TrackedHashLink trackingData={{section: 'chat'}} to="/#videos">Los geht's!</TrackedHashLink>
                    </span>
                </ChatCarouselMessage>
              </ChatCarouselPage>
            </ChatCarousel>
            <div className={styles.scrollHint + (fixed ? '' : ' ' + styles.hide)}>
              <img src={ScrollIcon}/>
              Scrollen zum Fortsetzen
            </div>
          </div>
          <div className={styles.SectionBackground} style={{backgroundPositionY}}/>
        </div>
      </div>
  );
}