import * as React from 'react';
import Img01 from '../../assets/product/gallery/Abstandssensor.jpg';
import Img02 from '../../assets/product/gallery/Button.jpg';
import Img03 from '../../assets/product/gallery/Tastatur.jpg';
import Img04 from '../../assets/product/gallery/2in1Operator.jpg';
import Img05 from '../../assets/product/gallery/2in1Funktionen.jpg';
import Img06 from '../../assets/product/gallery/Display.jpg';
import Img07 from '../../assets/product/gallery/Drehregler.jpg';
import Img08 from '../../assets/product/gallery/Leuchtring.jpg';
import Img09 from '../../assets/product/gallery/1in2Operator.jpg';
import Img10 from '../../assets/product/gallery/1in2Funktionen.jpg';
import Img11 from '../../assets/product/gallery/Strom-Atom + USB-Kabel frei.jpg';
import styles from './ProductImageCarousel.module.scss';

import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Thumbs, Controller} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/thumbs/thumbs.scss';
import {useContext, useState} from 'react';
import {useIsPhone} from '../../TechAtomHooks';
import type {GoogleAnalyticsContextProps} from '../../modules/tracking/GoogleAnalyticsContext';
import GoogleAnalyticsContext from '../../modules/tracking/GoogleAnalyticsContext';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Thumbs, Controller]);

type Props = {
  onSlideChange: ?(e) => void
}

export default function ProductImageCarousel(props: Props) {
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  const isPhone = useIsPhone();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const images = [Img01, Img02, Img03, Img04, Img05, Img06, Img07, Img08, Img09, Img10, Img11];
  
  function onSlideChange(e) {
    googleAnalytics.event('slide', {
      ...googleAnalytics.identifierParams('shop-product-top'),
      index_offset: e.previousIndex + ' -> ' + e.activeIndex
    });
    if (props.onSlideChange) {
      props.onSlideChange(e);
    }
  }
  return (
      <div className={styles.ProductImageCarousel}>
        {(isPhone || thumbsSwiper) && 
          <Swiper className={styles.images}
                  thumbs={isPhone ? null : {swiper: thumbsSwiper}}
                  pagination={{clickable: true}}
                  centeredSlides
                  navigation
                  onSlideChange={onSlideChange}
          >
            {
              images.map((img, imgindex) =>
                  <SwiperSlide key={'img' + imgindex}><img src={img} alt=""/></SwiperSlide>
              )
            }
          </Swiper>
        }
        {!isPhone && 
          <Swiper
              spaceBetween={5}
              slidesPerView= {5}
              className={styles.thumbs}
              onSwiper={setThumbsSwiper}
              
              watchSlidesVisibility
              watchSlidesProgress
              slideToClickedSlide
          >
            {
              images.map((img, imgindex) =>
                  <SwiperSlide key={'thumb' + imgindex}><img src={img} alt=""/></SwiperSlide>
              )
            }
          </Swiper>
        }
      </div>
  );
}