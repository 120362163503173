import * as React from 'react';
import SectionHeadline from '../components/SectionHeadline';
import EmbeddedVideo from '../../../components/EmbeddedVideo';
import ManufacturingIcon from './ManufacturingIcon';

import LocationIcon from '../../../assets/icons/manufacturing-location.png';
import Printing3DIcon from '../../../assets/icons/manufacturing-3dprinting.png';
import MaterialIcon from '../../../assets/icons/manufacturing-renewable.png';
import DefaultContentWrapper from '../../../components/DefaultContentWrapper';
import styles from './ManufacturingSection.module.scss';
import ParallaxHelper from '../../../modules/helper/ParallaxHelper';
import {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useIsPhone} from '../../../TechAtomHooks';

import SwiperCore, {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import type {GoogleAnalyticsContextProps} from '../../../modules/tracking/GoogleAnalyticsContext';
import GoogleAnalyticsContext from '../../../modules/tracking/GoogleAnalyticsContext';

import Img1 from './images/1.JPG';
import Img2 from './images/2.JPG';
import Img3 from './images/3.JPG';
import Img4 from './images/4.JPG';
import Img5 from './images/5.JPG';
import Img6 from './images/6.JPG';
import Img7 from './images/7.JPG';
import Img8 from './images/8.JPG';
import Img9 from './images/9.JPG';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const images = [
  Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9
];

export default function ManufacturingSection() {
  const mainDiv = useRef();
  const positions = ParallaxHelper.simpleSingleElementOffsetPositions('elementTopEnter', mainDiv, 800);
  const scrollenter = ParallaxHelper.useScrollStats(positions.start, positions.end);
  const isPhone = useIsPhone();
  const icons = [
    <ManufacturingIcon icon={LocationIcon} title="Entwicklung und Produktion in Deutschland"/>,
    <ManufacturingIcon icon={Printing3DIcon} title="Additive Fertigung (3D-Druck)"/>,
    <ManufacturingIcon icon={MaterialIcon} title="Druckmaterial aus nachwachsenden Rohstoffen"/>
  ];

  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);

  function onSlideChangeImages(e) {
    googleAnalytics.event('slide', {
      ...googleAnalytics.identifierParams('main manufacturing', 'images'),
      index_offset: e.previousIndex + ' -> ' + e.activeIndex
    });
  }

  function onSlideChange(e) {
    googleAnalytics.event('slide', {
      ...googleAnalytics.identifierParams('main manufacturing', 'icons'),
      index_offset: e.previousIndex + ' -> ' + e.activeIndex
    });
  }

  useSectionViewTracker('main manufacturing', mainDiv, {});

  return (
      <div className={styles.ManufacturingSection} ref={mainDiv} style={{
        '--scroll-enter': scrollenter.scrollRelativeCapped
      }}>
        <div className={styles.blendingBackground}>
          <DefaultContentWrapper>
            <SectionHeadline title="Ein TechAtom entsteht"/>
            <div className={styles.imagesContainer}>
              <div className={styles.images}>
                <Swiper pagination={isPhone ? false : {clickable: true}} navigation onSlideChange={onSlideChangeImages.bind(this)}>
                  {images.map((img, imgindex) =>
                      <SwiperSlide key={'i' + imgindex}>
                        <div><img src={img} alt=""/></div>
                      </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
            <div className={styles.icons}>
              {isPhone &&
               <Swiper pagination={{clickable: true}} onSlideChange={onSlideChange}>
                 {icons.map((icon, inx) =>
                     <SwiperSlide key={'slide' + inx}>{icon}</SwiperSlide>
                 )}
               </Swiper>
              }
              {!isPhone && icons.map((icon, inx) =>
                  <div key={'icon' + inx}>{icon}</div>
              )
              }
            </div>
          </DefaultContentWrapper>
        </div>
      </div>
  );
}