import * as React from 'react';
import TopMenu from './TopMenu';
import Footer from './Footer';
import '../basic.scss';

export default class DefaultPageContainer extends React.Component {

  render() {
    return (
        <div>
            <TopMenu />
            {this.props.children}
            <Footer/>
        </div>
    );
  }
}