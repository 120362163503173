import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

export default function useScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '' || location.hash === '#') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
}