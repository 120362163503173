import * as React from 'react';
import styles from './EduExplainCard.module.scss';

export default class EduExplainCard extends React.Component {

  render() {
    return (
        <div className={styles.EduExplainCard + (this.props.textLeft ? ' '+styles.textLeft : '')}>
          <div className={styles.image}>
            <img src={this.props.icon}/>
          </div>
          <div className={styles.content}>
            <span>{this.props.children}</span>
          </div>
        </div>
    );
  }
}