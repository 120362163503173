import { useLayoutEffect, useState } from 'react';

type SectionScrollStats = {
  topPosition: number,
  bottomPosition: number,
  inside: boolean,
  scrollOffsetTop: number,
  scrollOffsetBottom: number,
  scrollRelative: number,
  scrollRelativeCapped: number,
};

export default class ParallaxHelper {
  static positionFromDefinition(definition) {
    let position = 0;
    const elementRect =
      definition.ref &&
      definition.ref.current &&
      definition.ref.current.getBoundingClientRect();
    switch (definition.type) {
      case 'pagetop':
        position = 0;
        break;
      case 'pagebottom':
        position = document.body.scrollHeight;
        break;
      case 'elementTopEnter':
        if (elementRect) {
          position =
            elementRect.top + window.scrollY - window.innerHeight;
        }
        break;
      case 'elementTopLeave':
        if (elementRect) {
          position = elementRect.top + window.scrollY;
        }
        break;
      case 'elementBottomEnter':
        if (elementRect) {
          position =
            elementRect.bottom + window.scrollY - window.innerHeight;
        }
        break;
      case 'elementBottomLeave':
        if (elementRect) {
          position = elementRect.bottom + window.scrollY;
        }
        break;
    }
    if (definition.offset) {
      position += definition.offset;
    }
    return position;
  }

  static positionFromTypeAndRef(type, ref) {
    return ParallaxHelper.positionFromDefinition({
      type,
      ref,
    });
  }

  static sectionScrollStats(
    topPosition,
    bottomPosition,
    scrollY
  ): SectionScrollStats {
    const scrollRelative =
      (scrollY - topPosition) / (bottomPosition - topPosition);
    const scrollRelativeCapped =
      scrollRelative < 0
        ? 0
        : scrollRelative > 1
        ? 1
        : scrollRelative;
    return {
      topPosition: topPosition,
      bottomPosition: bottomPosition,
      inside: scrollRelative >= 0 && scrollRelative <= 1,
      scrollOffsetTop: scrollY - topPosition,
      scrollOffsetBottom: scrollY - bottomPosition,
      scrollRelative,
      scrollRelativeCapped,
    };
  }

  static calculatePositions(positionDefinitions) {
    return Object.fromEntries(
      Object.entries(positionDefinitions).map(([key, def]) => {
        return [key, ParallaxHelper.positionFromDefinition(def)];
      })
    );
  }

  static simpleEnterLeavePositions(ref) {
    const positionRefs = {
      enter: {
        type: 'elementTopEnter',
        ref: ref,
        offset: 0,
      },
      leave: {
        type: 'elementBottomLeave',
        ref: ref,
        offset: 0,
      },
    };
    return ParallaxHelper.calculatePositions(positionRefs);
  }

  static simpleSingleElementOffsetPositions(type, ref, offset) {
    const positionRefs = {
      start: {
        type,
        ref: ref,
        offset: offset < 0 ? offset : 0,
      },
      end: {
        type,
        ref: ref,
        offset: offset > 0 ? offset : 0,
      },
    };
    return ParallaxHelper.calculatePositions(positionRefs);
  }

  static useScrollStats(
    startPosition,
    leavePosition
  ): SectionScrollStats {
    // eslint-disable-next-line
    const [scrollStats, setScrollStats] = useState({});

    // eslint-disable-next-line
    useLayoutEffect(() => {
      const handleScroll = () => {
        setScrollStats(
          ParallaxHelper.sectionScrollStats(
            startPosition,
            leavePosition,
            window.scrollY
          )
        );
      };

      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollStats, startPosition, leavePosition]);

    return scrollStats;
  }

  static useScrollY() {
    // eslint-disable-next-line
    const [scrollY, setScrollY] = useState(window.scrollY);

    // eslint-disable-next-line
    useLayoutEffect(() => {
      const handleScroll = () => {
        setScrollY(window.scrollY);
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    });
    return scrollY;
  }

  static useIsScrolledUp(minYActivate) {
    // eslint-disable-next-line
    const [scrollY, setScrollY] = useState(window.scrollY);
    // eslint-disable-next-line
    const [scrolledUp, setScrolledUp] = useState(true);

    // eslint-disable-next-line
    useLayoutEffect(() => {
      const handleScroll = () => {
        setScrollY(window.scrollY);
        const n_scrolledUp =
          window.scrollY < scrollY || window.scrollY < minYActivate;
        if (n_scrolledUp != scrolledUp) {
          setScrolledUp(n_scrolledUp);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    });
    return scrolledUp;
  }
}
