import * as React from 'react';
import styles from './ProductConfigurationMenu.module.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ColorGalaxyBlueImg from './images/color_galaxyblue.png';
import ColorGalaxyPurpleImg from './images/color_galaxypurple.png';
import ColorLightblueImg from './images/color_lightblue.png';
import ColorGreyImg from './images/color_grey.png';
import ColorNeongreenImg from './images/color_neongreen.png';
import ColorNeonorangeImg from './images/color_neonorange.png';
import ColorOpalGreenImg from './images/color_opalgreen.png';
import ColorPurpleImg from './images/color_purple.png';
import ColorSilverImg from './images/color_silver.png';
import {useContext, useState} from 'react';
import type {DataPrivacyContextProps} from '../../modules/dataprivacy/DataPrivacyContext';
import DataPrivacyContext from '../../modules/dataprivacy/DataPrivacyContext';
import type {GoogleAnalyticsContextProps} from '../../modules/tracking/GoogleAnalyticsContext';
import GoogleAnalyticsContext from '../../modules/tracking/GoogleAnalyticsContext';

const colors = {
  galaxyblue: {
    image: ColorGalaxyBlueImg,
    name: 'Sternenblau'
  },
  galaxypurple: {
    image: ColorGalaxyPurpleImg,
    name: 'Galaxy Purple'
  },
  lightblue: {
    image: ColorLightblueImg,
    name: 'Hellblau'
  },
  grey: {
    image: ColorGreyImg,
    name: 'Effektgrau'
  },
  neongreen: {
    image: ColorNeongreenImg,
    name: 'Neongrün'
  },
  neonorange: {
    image: ColorNeonorangeImg,
    name: 'Neonorange'
  },
  opalgreen: {
    image: ColorOpalGreenImg,
    name: 'Opalgrün'
  },
  purple: {
    image: ColorPurpleImg,
    name: 'Magenta'
  },
  silver: {
    image: ColorSilverImg,
    name: 'Silber'
  }
};

const atomsdata = [
  {
    name: 'Atom: Abstandssensor',
    color: 'lightblue'
  },
  {
    name: 'Atom: Knopf',
    color: 'neongreen'
  },
  {
    name: 'Atom: Tastatur',
    color: 'silver'
  },
  {
    name: 'Atom: Operator 2-zu-1',
    color: 'galaxyblue'
  },
  {
    name: '2-zu-1 Funktionen',
    color: 'neonorange'
  },
  {
    name: 'Atom: Display',
    color: 'grey'
  },
  {
    name: 'Atom: Drehregler',
    color: 'purple'
  },
  {
    name: 'Atom: Farbring',
    color: 'grey'
  },
  {
    name: 'Atom: Operator 1-zu-2',
    color: 'opalgreen'
  },
  {
    name: '1-zu-2 Funktionen',
    color: 'lightblue'
  },
  {
    name: 'Strom-Atom',
    color: 'galaxypurple'
  }
];

type Props = {
  currentatom: ?string,
};

export default function ProductConfigurationMenu(props: Props) {
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  const [showMessage, setShowMessage] = useState(false);

  
  function showMessageTimed(action, label, e) {
    googleAnalytics.event('item_facet_selection', {
      ...googleAnalytics.identifierParams('shop product-top', action, label)
    });
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 10000);
    e.stopPropagation();
    return false;
  }

  const currentatom = props.currentatom || 0;
  const atom = atomsdata[currentatom];
  const currentcolor = colors[atom.color];
  return (
      <div className={styles.ProductConfigurationMenu}>
        <h1>Personalisiere mich</h1>
        <div className={styles.currentAtom}>
          <h1>{atom.name}</h1>
          <div className={styles.productOption + ' ' + styles.disabled}>
            <h3>Farbe wählen: <span>({currentcolor.name})</span></h3>
            <div className={styles.colors}>

              {Object.entries(colors).map(([colkey, coldata]) =>
                  <div
                      className={styles.colorTile + (atom.color === colkey ? ' ' + styles.activeColor : '')}
                      style={{backgroundImage: 'url(' + coldata.image + ')'}}
                      onClick={showMessageTimed.bind(this, 'color changed', colkey + ' for ' + atom.name)}
                  />
              )}
            </div>
          </div>
        </div>
        <div className={styles.productOption + ' ' + styles.disabled}>
          <h3>Zubehör wählen:</h3>
          <div
              className={styles.selectBox}
              onClick={showMessageTimed.bind(this, 'accessories', undefined)}
          >
            Standard: Inkl. USB-Netzteil
          </div>
          <div className={styles.arrow}><KeyboardArrowDownIcon/></div>
        </div>
        <div className={styles.leftArrow}></div>
        <div className={styles.notAvailable + (showMessage ? ' ' + styles.showMessage : '')}>
          Aktuell ist diese Auswahl leider noch nicht möglich. Wenn Du uns Deine E-Mail Adresse da lässt, 
          benachrichtigen wir Dich sofort, wenn TechAtom verfügbar ist! :)
        </div>
      </div>
  );
}