import * as React from 'react';
import DefaultPageContainer from '../components/DefaultPageContainer';
import DefaultPageContent from '../components/DefaultPageContent';
import styles from './Betatester.module.scss';
import useScrollToTop from '../modules/helper/useScrollToTop';
import type {GoogleAnalyticsContextProps} from '../modules/tracking/GoogleAnalyticsContext';
import {useContext} from 'react';
import GoogleAnalyticsContext from '../modules/tracking/GoogleAnalyticsContext';
import Helmet from 'react-helmet';

export default function Betatester() {
  useScrollToTop();

  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  function onClick() {
    googleAnalytics.event('betauser_email', {
      ...googleAnalytics.identifierParams(
          'betauser'
      )
    });
  }

  return <DefaultPageContainer>
    <Helmet>
      <title>Betatester | TechAtom – Die Programmiersprache zum Anfassen</title>
      <meta
          name="description"
          content=""
      />
      <meta property="og:title" content="Betatester | TechAtom – Die Programmiersprache zum Anfassen"/>
      <meta property="og:description" content=""/>
    </Helmet>
    <DefaultPageContent>
      <div className={styles.content}>
        <h1 className={styles.headline}>Werde Betatester*in</h1>
        <p className={styles.bigP}>Liebe*r Besucher*in,</p>
        <p>Du scheinst Dich sehr für TechAtom zu interessieren - darüber freuen wir uns! :)</p>
        <p>Wir befinden uns gerade in der Entwicklungsphase des Produktes, daher kannst Du TechAtom aktuell noch nicht bestellen.</p>
        <p>Hast Du Lust daran mitzuwirken, TechAtom zum Leben zu erwecken? <strong>Dann melde Dich als Betatester bei uns,</strong> und Du kannst das
          Produkt vor allen anderen in den Händen halten und es ausgiebig testen. Das ganze ist natürlich kostenlos - und als Dank für Deine
          ausführliche Rückmeldung wartet eine kleine Überraschung auf Dich!</p>
        <p>Interesse geweckt? Melde Dich bei uns unter <a onClick={onClick} href="mailto:betatester@techatom.de">betatester@techatom.de</a> und erzähle uns, warum wir
          Dich als Betatester auswählen sollten :). Solltest Du zu den Glücklichen gehören, bekommst Du nähere Informationen von uns per E-Mail. Wir
          freuen uns auf Dich!</p>
        <p className={styles.bigP}>Viele Grüße,</p>
        <p className={styles.bigP}>Dein TechAtom-Team</p> 
      </div>
    </DefaultPageContent>
  </DefaultPageContainer>;
}