import * as React from 'react';
import EmbeddedVideo from '../../../components/EmbeddedVideo';
import styles from './ExampleVideoCard.module.scss';
type Props = {
  onMouseEnter: ?() => void,
  onClick: ?() => void,
};

export default function ExampleVideoCard(props: Props) {
  return (
      <div className={styles.ExampleVideoCard}>
        <h2>{props.name}</h2>
        <div className={styles.image} onMouseEnter={props.onMouseEnter} onClick={props.onClick}>
          <img className={styles.imageDefault} src={props.image} alt=""/>
          <img className={styles.imageAnimated} src={props.imageAnimated} alt=""/>
        </div>
        <div className={styles.video}>
          <EmbeddedVideo vimeoVideoId={props.vimeoVideoId}/>
        </div>
      </div>
  );
}