import * as React from 'react';
import VideoBackground from './VideoBackground';
import styles from './IntroSection.module.scss';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';
import {useRef} from 'react';

export default function IntroSection() {
  const mainDiv = useRef();
  useSectionViewTracker('main intro', mainDiv, {});
  return (
      <div ref={mainDiv} className={styles.IntroSection}>
        <VideoBackground vimeoVideoId="473025945">
          <div className={styles.pageContent}>
            <div className={styles.ctaContent}>
              <h1>TechAtom</h1>
              <p>&gt;_Die Programmiersprache zum Anfassen</p>
            </div>
          </div>
        </VideoBackground>
      </div>
  );
}