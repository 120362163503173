import * as React from 'react';
import ProductImageCarousel from './ProductImageCarousel';
import ProductConfigurationMenu from './ProductConfigurationMenu';
import AvailabilitySubscribe from './AvailabilitySubscribe';
import './ProductPageTop.scss';
import LogoFull from '../../assets/images/Logo_single.gif';
import {useState} from 'react';

export default function ProductPageTop() {
  const [currentAtom, setCurrentAtom] = useState(0);
  
  function onSlideChange(e) {
    const activeIndex = e.activeIndex;
    setCurrentAtom (activeIndex);
  }

  return (
      <div className="ProductPageTop">
        <div className="top-carousel">
          <ProductImageCarousel onSlideChange={onSlideChange}/>
        </div>
        <div className="top-right">
          <div className="top-right-logo">
            <img src={LogoFull} alt=""/>
          </div>
          <h1>TechAtom 'Starter-Set'</h1>
          <p><strong>Status:</strong> <a href="#not-ready-yet">Noch nicht verfügbar<sup>*</sup></a></p>
          <ProductConfigurationMenu currentatom={currentAtom}/>
          <AvailabilitySubscribe/>
        </div>
      </div>
  );
}