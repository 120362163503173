import * as React from 'react';
import styles from './CookieConsentNotification.module.scss';
import {HashLink} from 'react-router-hash-link';
import {DataPrivacyConsumer} from '../DataPrivacyContext';
import type {DataPrivacyContextProps} from '../DataPrivacyContext';

export default function CookieConsentNotification() {
  return (
      <DataPrivacyConsumer>
        {(dataPrivacy: DataPrivacyContextProps) => {
          if (!dataPrivacy.showConsentNotification) {
            return null;
          }
          return <div className={styles.CookieConsentNotification}>
            <div className={styles.consentText}>
              <h2>Cookies und Datenschutz bei TechAtom</h2>
              <p>Wir verwenden Cookies, um unsere Website optimal für Dich zu gestalten und die Zugriffe auf unsere Website zu analysieren. 
              </p>

              <p>Wenn Du „Okay“ sagst, bist Du damit einverstanden und erlaubst uns, diese Daten an Dritte weiterzugeben,
                etwa an unsere Marketingpartner. Dies schließt gegebenenfalls die Verarbeitung Deiner Daten in den USA ein.
                Falls Du dem nicht zustimmen magst, beschränken wir uns auf die technisch notwendigen Cookies. 
                Weitere Details und alle Optionen findest Du in den „Einstellungen“. Du kannst diese auch später jederzeit anpassen. 
                Weitere Informationen findest Du in unserer <HashLink to="/datenschutz">Datenschutzerklärung</HashLink>.</p>
            </div>
            <div className={styles.buttonsWrapper}>
              <div className={styles.buttons}>
          <span className={styles.button + ' ' + styles.dark} onClick={() => dataPrivacy.showSettings()}>
            Einstellungen
          </span>
                <span className={styles.button} onClick={() => dataPrivacy.acceptAll()}>
            Okay
          </span>
              </div>
            </div>
          </div>
        }}
      </DataPrivacyConsumer>
  );
};