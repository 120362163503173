import * as React from 'react';
import './ProductPageDescription.scss';
import LogoGif from '../../assets/images/Icon.gif';
import Img01 from '../../assets/product/description/TA in Aktion 2 4-3.JPG';
import Img02 from '../../assets/product/description/Abstandswarner 4-3.JPG';
import Img03 from '../../assets/product/description/Lichtorgel 4-3.JPG';
import Img04 from '../../assets/product/description/Gesamtset blauer HG 4-3.jpg';
import {useIsPhone} from '../../TechAtomHooks';

export default function ProductPageDescription() {
  const isPhone = useIsPhone();
  return (
      <div className="ProductPageDescription">
        <div className="columns-left-right">
          <div>
            <h3>Produktbeschreibung:</h3>
            <p>
              Lass auch Du Dich von TechAtom in den Bann ziehen und hole Dir jetzt das TechAtom Starter-Set nach Hause. Im Set ist alles enthalten, was
              Du für den optimalen Start brauchst: Mit verschiedenen Inputatomen, vielfältigsten Möglichkeiten der Datenverarbeitung und unserem
              coolen Leuchtring und dem stylischen Display-Atom steht
            </p>
          </div>
          <div className="product-image">
            <img src={Img01} alt=""/>
          </div>
        </div>
        <div className="columns-right-left">
          <div>
            <p>
              Deinem Start in die TechAtom-Welt nichts mehr im Wege. Du hast jetzt sogar die Möglichkeit, Dir Deine Wunschfarbe und -zubehör
              auszusuchen und TechAtom so zu Deinem ganz persönlichen Lieblingsspielzeug zu machen.
            </p>
            <p>
              Worauf wartest Du noch?

              {!isPhone && 
                <span className="animated-logo">
                    <img src={LogoGif} alt=""/>
                  </span>
              }
            </p>
          </div>
          <div className="product-image">
            <img src={Img02} alt=""/>
          </div>
        </div>
        <div className="columns-left-right">
          <div>
          <strong>Lieferumfang Starter-Set:</strong>
            <ul>
              <li>Button, 2x</li>
              <li>Drehregler, 2x</li>
              <li>8-Bit-Tastatur, 1x</li>
              <li>Abstandssensor, 1x</li>
              <li>2-in-1-Operator, 6x | 1-in-2-Operator, 2x</li>
              <li>Leuchtring, 1x</li>
              <li>Display-Atom, 1x</li>
              <li>Funktionsplättchen, 15x</li>
              <li>Strom-Atom inkl. USB-C-Kabel & Netzteil, 1x</li>
              <li>Verbindungskabel, 2x​​</li>
            </ul>
          
          </div>
          <div className="product-image">
            <img src={Img03} alt=""/>
          </div>
        </div>
        <div className="columns-right-left">
          <div>
            <p>
              <strong>Herstellung:</strong>
              <ul>
                <li>Entwicklung und Fertigung in Hamburg, Deutschland.</li>
              </ul>
            </p>
            <p>
              <strong>Material:</strong>
              <ul>
                <li>PLA-Filament, hergestellt aus nachwachsenden Rohstoffen​.</li>
              </ul>
            </p>
            <p>
              <strong>Empfohlene Altersgruppe:</strong>
              <ul>
                <li>12-16 Jahre​.</li>
              </ul>
            </p>
          </div>
          <div className="product-image">
            <img src={Img04} alt=""/>
          </div>
        </div>
      </div>
  );
}