import * as React from 'react';

const AppConfigContext = React.createContext();

type State = {
  config: AppConfig
}

export type AppConfig = {
  environment: string,
  urlOrigin: string,
  gaMeasurementId: string,
  robotsIndexing: boolean
}

export const AppConfigContextConsumer = AppConfigContext.Consumer;

export class AppConfigProvider extends React.Component {
  // Context state
  state: State;
  context: AppConfig;
  static contextType = AppConfigContext;

  constructor() {
    super();
    this.state = {
      config: {
        environment: 'local',
        urlOrigin: document.location.origin,
        gaMeasurementId: '',
        robotsIndexing: false
      }
    };
    //this.acceptAll = this.acceptAll.bind(this);
  }

  componentWillMount() {
    if (document.location.hostname === 'www.techatom.de' || document.location.hostname === 'techatom.de') {
      this.setState({
        config: {
          environment: 'production',
          urlOrigin: 'https://www.techatom.de',
          gaMeasurementId: 'G-H0JP5L48P1',
          robotsIndexing: true
        }
      });
    } else {
      this.setState({
        config: {
          environment: (document.location.hostname.indexOf('techatom.de') !== -1 && document.location.hostname.indexOf('staging') !== -1) ? 'staging' : 'local',
          gaMeasurementId: 'G-BLLE36M5JD',
          ...this.state.config
        }
      });
    }
  }

  render() {
    const {children} = this.props;
    return (
        <AppConfigContext.Provider
            value={this.state.config}
        >
          {children}
        </AppConfigContext.Provider>
    );
  }
}

export default AppConfigContext;
