import * as React from 'react';
import styles from './ChatCarousel.module.scss';
import {useRef} from 'react';

export default function ChatCarousel(props) {
  const mainDiv = useRef();
  if (mainDiv.current) {
    const rect = mainDiv.current.getBoundingClientRect();
    const scrollDistances = props.scrollDistances;
    const sumInputDistance = scrollDistances.map(v => v.inputDistance).reduce((a, b) => a + b, 0);
    const sumOutputDistance = scrollDistances.map(v => v.outputDistance).reduce((a, b) => a + b, 0);
    const input = props.scrollPercentage * sumInputDistance;
    let output = 0;
    let inAgg = 0;
    scrollDistances.forEach((v) => {
      const inDis = v.inputDistance;
      const outDis = v.outputDistance;
      if (inAgg + inDis < input) {
        output += outDis;
      } else if (inAgg < input) {
        const frac = (input - inAgg) / inDis;
        output += outDis * frac;
      }
      inAgg += inDis;
    });
    mainDiv.current.scrollTop = (output / sumOutputDistance) * (mainDiv.current.scrollHeight - rect.height);
  }

  return (
      <div className={styles.ChatCarousel}>
        <div ref={mainDiv}>
          {props.children}
        </div>
      </div>
  );
}