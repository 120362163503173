import * as React from 'react';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import styles from './Shop.module.scss';
import ProductPageTop from './ProductPageTop';
import ProductPageDescription from './ProductPageDescription';
import ProductNotReadyYet from './ProductNotReadyYet';
import BigActionButton from '../../components/BigActionButton';
import DefaultPageContent from '../../components/DefaultPageContent';
import ShopBackground from './ShopBackground';
import useScrollToTop from '../../modules/helper/useScrollToTop';
import Helmet from 'react-helmet';
import LogoImg from '../../assets/images/Icon_512px.png';
import type {AppConfig} from '../../modules/config/AppConfigContext';
import {useContext} from 'react';
import AppConfigContext from '../../modules/config/AppConfigContext';
import Img01 from '../../assets/product/description/TA in Aktion 2 4-3.JPG';

export default function Shop() {
  const appConfig: AppConfig = useContext(AppConfigContext);
  useScrollToTop();
  return <DefaultPageContainer>
    <Helmet>
      <title>Shop | TechAtom – Die Programmiersprache zum Anfassen</title>
      <meta
          name="description"
          content="Bestelle Dir jetzt Dein persönliches TechAtom Starter-Set nach Hause und starte sofort durch! Hast Du Dir Deine Lieblingsfarben schon ausgesucht?"
      />
      <meta property="og:title" content="Shop | TechAtom – Die Programmiersprache zum Anfassen"/>
      <meta property="og:description" content="Bestelle Dir jetzt Dein persönliches TechAtom Starter-Set nach Hause und starte sofort durch! Hast Du Dir Deine Lieblingsfarben schon ausgesucht?"/>
      <meta property="og:image" content={appConfig.urlOrigin + Img01}/>
      <meta name="twitter:card" content="summary_large_image"/>
    </Helmet>
    <DefaultPageContent>
      <ShopBackground/>
      <ProductPageTop/>
      <ProductPageDescription/>
      <div className={styles.betaUsers}>
        <BigActionButton toLink="/betatester" title="Mehr Info ->"/>
      </div>
      <a id="not-ready-yet"></a>
      <ProductNotReadyYet/>
    </DefaultPageContent>
  </DefaultPageContainer>;
}