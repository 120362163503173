import * as React from 'react';
import Atom, {AtomFrame} from './Atom';
import styles from './LedRingAtom.module.scss';
import type {AtomProps} from './Atom';

type Props = {
  ...AtomProps,
  colors: ?[{ red: number, green: number, blue: number }]
}

const lightsCount = 24;
let lights = [];
const radius = 297;
// 218
for (let i = 0; i < lightsCount; i++) {
  const angle = i / lightsCount * 2 * Math.PI;
  lights.push({
    key: 'light' + i,
    x: 465 + Math.sin(angle) * radius,
    y: 513 - Math.cos(angle) * radius
  });
}

export default function LedRingAtom(props: Props) {
  const colors = props.colors || [{red: 0, green: 0, blue: 0}];
  return <AtomFrame className={styles.LedRingAtom} {...props}>
    {lights.map((light, inx) => {
      const colinx = Math.floor(inx / lightsCount * colors.length);
      return <div key={light.key} className={styles.lighton} style={{
        left: light.x + 'px',
        top: light.y + 'px',
        '--light-red': colors[colinx].red,
        '--light-green': colors[colinx].green,
        '--light-blue': colors[colinx].blue,
      }}>
        <div className={styles.sourceSpread}/>
        <div className={styles.source}/>
        <div className={styles.sourceBeam}/>
      </div>;
    }
    )}
  </AtomFrame>;
};