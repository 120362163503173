import * as React from 'react';
import styles from './SectionHeadline.module.scss';

export default class SectionHeadline extends React.Component {

  render() {
    return (
        <div className={
          styles.SectionHeadline + 
          (this.props.darkBackground ? ' '+styles.darkBackground : '') +
          (this.props.className ? ' '+this.props.className : '')
        }>
          <div className={styles.inner}>
            <h1 dangerouslySetInnerHTML={{__html: this.props.title}}/>
            {this.props.subtitle &&
             <h2 dangerouslySetInnerHTML={{__html: this.props.subtitle}}/>
            }
          </div>
        </div>
    );
  }
}