import * as React from 'react';

export default class EmbeddedVideo extends React.Component {

  render() {
    return (
        <iframe width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen"
                src={'https://player.vimeo.com/video/' + this.props.vimeoVideoId
                     + '?background=0&muted=0&autoplay=0&byline=0&portrait=0&title=0&logo=0'}>
        </iframe>
    );
  }
}