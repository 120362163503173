import * as React from 'react';
import styles from './DataPrivacySettings.module.scss';
import DataPrivacyContext, {DataPrivacyConsumer} from '../DataPrivacyContext';
import type {DataPrivacyContextProps} from '../DataPrivacyContext';
import {useContext, useEffect, useState} from 'react';
import type {GoogleAnalyticsContextProps} from '../../tracking/GoogleAnalyticsContext';
import GoogleAnalyticsContext from '../../tracking/GoogleAnalyticsContext';

export default function DataPrivacySettings() {
  const dataPrivacy: DataPrivacyContextProps = useContext(DataPrivacyContext);
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);
  const settings = dataPrivacy.settings;
  const [newSettings, setNewSettings] = useState(settings);

  function onChangeSetting(category, newValue) {
    setNewSettings((nset) => {
      return {
        ...nset,
        categoriesEnabled: {
          ...nset.categoriesEnabled,
          [category]: newValue
        }
      };
    });
  }

  const getEnabledCategories = (settings) => {
    if (!settings) {
      settings = dataPrivacy.settings;
    }
    return Object.entries(settings.categoriesEnabled).
        filter(([category, enabled]) => enabled).
        map(([category, enabled]) => category).
        join(' ');
  };

  useEffect(() => {
    googleAnalytics.event('modal_open', {
      identifier: 'data-privacy-settings',
      enabledCategories: getEnabledCategories()
    });
    return () => {
    };
  }, []);

  function onClose() {
    dataPrivacy.showSettings(false);
    googleAnalytics.event('modal_close', {
      identifier: 'data-privacy-settings',
      action: 'cancel',
      enabledCategories: getEnabledCategories()
    });
  }

  function onSave() {
    dataPrivacy.updateSettings(newSettings, null, (settings) => {
      googleAnalytics.event('modal_close', {
        identifier: 'data-privacy-settings',
        action: 'save',
        enabledCategories: getEnabledCategories(settings)
      });
    });
  }

  function onAcceptAll() {
    dataPrivacy.acceptAll((settings) => {
      googleAnalytics.event('modal_close', {
        identifier: 'data-privacy-settings',
        action: 'accept-all',
        enabledCategories: getEnabledCategories(settings)
      });
    });
  }

  return (<div className={styles.background}>
        <div className={styles.DataPrivacySettings}>
          <h2>Datenschutz-Einstellungen</h2>
          <p>Hier kannst Du festlegen, welche Cookies Du zulassen oder ausschließen möchtest.</p>
          <div className={styles.settings}>
            <div className={styles.setting}>
              <label className={styles.switch}>
                <input type="checkbox"
                       checked={!!newSettings.categoriesEnabled.tracking}
                       onChange={onChangeSetting.bind(this, 'tracking', !newSettings.categoriesEnabled.tracking)}
                />
                <span className={styles.slider}/>
              </label>
              <div className={styles.description}>
                <h1>Web-Performance und Analyse</h1>
                <p>Diese Cookies helfen uns zu verstehen, wie Besucher*innen mit unserer Webseite interagieren,
                  indem Informationen anonym gesammelt und gemeldet werden.</p>
              </div>
            </div>
            <div className={styles.setting}>
              <label className={styles.switch}>
                <input type="checkbox"
                       checked={!!newSettings.categoriesEnabled.marketing}
                       onChange={onChangeSetting.bind(this, 'marketing', !newSettings.categoriesEnabled.marketing)}
                />
                <span className={styles.slider}/>
              </label>
              <div className={styles.description}>
                <h1>Marketing</h1>
                <p>Wir verwenden Marketing-Cookies, um unseren Besucher*innen ausschließlich solche Anzeigen zu zeigen,
                  die individuell relevant und ansprechend sind.</p>
              </div>
            </div>
            <div className={styles.setting}>
              <label className={styles.switch}>
                <input type="checkbox"
                       checked={!!newSettings.categoriesEnabled.functional}
                       onChange={onChangeSetting.bind(this, 'functional', !newSettings.categoriesEnabled.functional)}
                />
                <span className={styles.slider}/>
              </label>
              <div className={styles.description}>
                <h1>Personalisierung</h1>
                <p>Personaliserungs-Cookies ermöglichen es unserer Webseite, sich an Informationen zu erinnern, die beeinflussen, wie sie sich
                  verhält oder aussieht, wie z.B. Deine bevorzugte Sprache oder die Region in der Du Dich befindest.</p>
              </div>
            </div>
            <div className={styles.setting}>
              <label className={styles.switch}>
                <input type="checkbox"
                       checked={!!newSettings.categoriesEnabled.personalization}
                       onChange={onChangeSetting.bind(this, 'personalization', !newSettings.categoriesEnabled.personalization)}
                />
                <span className={styles.slider}/>
              </label>
              <div className={styles.description}>
                <h1>Funktional</h1>
                <p>Funktionale Cookies werden verwendet, um zusätzliche Features bereit zu stellen, die Dein Website-Erlebnis verbessern und um Bugs
                  vorzubeugen.</p>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
                <span className={styles.button + ' ' + styles.dark} onClick={onClose}>
                  Abbrechen
                </span>
            <span className={styles.button + ' ' + styles.dark} onClick={onSave}>
                  Speichern
                </span>
            <span className={styles.button} onClick={onAcceptAll}>               
              Alle akzeptieren
            </span>
          </div>
        </div>
      </div>
  );
};