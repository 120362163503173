import styles from './StickyAtomFoto.module.scss';
import Tape from './images/tape.png';
import * as React from 'react';
import {useState} from 'react';

export default function StickyAtomFoto(props) {
  const [tapeRotation, setTapeRotation] = useState(-40 * Math.random());
  const [tapeScale, setTapeScale] = useState(Math.random() > 0.5 ? 1 : -1);
  const [rotation, setRotation] = useState(Math.random() * 4 - 1);
  return (
      <div className={styles.StickyAtomFoto} style={{
        '--sticky-note-rotation': rotation + 'deg',
        '--sticky-tape-rotation': tapeRotation + 'deg',
        '--sticky-tape-scale': tapeScale
      }}
           onMouseEnter={props.onMouseEnter}
           onClick={props.onClick}
      >
        <div className={styles.content}>
          <img src={props.image} alt=""/>
          <div className={styles.hoverContent}>
            {props.children}
          </div>
        </div>
        <img className={styles.tape} src={Tape} alt=""/>
      </div>
  );
};