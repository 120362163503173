import * as React from 'react';
import SectionHeadline from '../components/SectionHeadline';
import BigActionButton from '../../../components/BigActionButton';
import BasketIconWhite from '../../../assets/Bilder/Basket Icon White.png';
import LogoIcon from '../../../assets/images/Icon_512px.png';
import {Link} from 'react-router-dom';
import styles from './ToShopSection.module.scss';
import {useRef} from 'react';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';

export default function ToShopSection() {
  const mainDiv = useRef();
  useSectionViewTracker('main shop-link', mainDiv, {});
  return (
      <div ref={mainDiv} className={styles.ToShopSection}>
        <div className={styles.sectionContainer}>
          <div className={styles.bigIcon}>
            <SectionHeadline title="Neugierig?" darkBackground/>
            <Link to="/shop">
              <img className={styles.logo} src={LogoIcon} alt=""/>
              <img className={styles.basket} src={BasketIconWhite} alt=""/>
            </Link>
          </div>
          <div className={styles.toShopButton}>
            <BigActionButton darkBackground toLink="/shop" title="Hier entlang ->"/>
          </div>
        </div>
      </div>
  );
}