import * as React from 'react';
import BigActionButton from '../../../components/BigActionButton';
import SectionHeadline from '../components/SectionHeadline';

import TaproLogo from '../../../assets/images/taprolabs_512.png';
import styles from './TaprolabsSection.module.scss';
import {useRef} from 'react';
import ParallaxHelper from '../../../modules/helper/ParallaxHelper';
import useSectionViewTracker from '../../../modules/tracking/useSectionViewTracker';

export default function TaprolabsSection() {
  const mainDiv = useRef();
  const positions = ParallaxHelper.simpleSingleElementOffsetPositions('elementTopEnter', mainDiv, 500);
  const scrollenter = ParallaxHelper.useScrollStats(positions.start, positions.end);

  useSectionViewTracker('main team', mainDiv, {});
  
  return (
      <div className={styles.TaprolabsSection} ref={mainDiv} style={{
        '--scroll-enter': scrollenter.scrollRelativeCapped
      }}>
        <div className={styles.sectionContainer}>
          <div className={styles.logo}>
            <a href="https://www.taprolabs.de" target="_blank">
              <img src={TaproLogo} alt="tapro labs logo"/>
            </a>
          </div>
          <div className={styles.text}>
            <div className={styles.headline}>
              <SectionHeadline title="Team tapro labs"/>
            </div>
            <p>Hinter TechAtom steht <a href="https://www.taprolabs.de" target="_blank">tapro labs</a>. Ein junges StartUp aus Hamburg, dessen Fokus
              auf
              der Entwicklung von Produkten und digitalen Plattformen für zeitgemäße Bildung liegt.</p>
            <div className={styles.button}>
              <BigActionButton toLink="https://www.taprolabs.de" externalLink title="Lerne tapro labs kennen"/>
            </div>
          </div>
        </div>
      </div>
  );
}