import * as React from 'react';

const DataPrivacyContext = React.createContext();

const defaultSettings = {
  userConfirmed: false,
  categoriesEnabled: {},
  servicesEnabled: {}
};

export type ServiceDescription = {
  key: string,
  name: string,
  category: string
};

export type DataPrivacyState = {
  showConsentNotification: boolean,
  settingsVisible: boolean,
  settings: DataPrivacySettings,
  services: {[string]: ServiceDescription}
}

export type DataPrivacySettings = {
  userConfirmed: boolean,
  categoriesEnabled: {
    tracking?: boolean,
    marketing?: boolean,
    personalization?: boolean,
    functional?: boolean,
  },
  servicesEnabled: { [string]: boolean },
}

export type DataPrivacyContextProps = {
  showConsentNotification: boolean,
  settingsVisible: boolean,
  settings: DataPrivacySettings,
  registerService: (service: ServiceDescription, callback?: () => void) => void,
  acceptAll: (callback?: (newSettings: DataPrivacySettings) => {}) => void,
  showSettings: () => void,
  updateSettings: (newSettings: DataPrivacySettings, closeSettings?: boolean, callback?: (newSettings: DataPrivacySettings) => {}) => void,
  isCategoryEnabled: (category: string) => boolean,
  isServiceEnabled: (service: string) => boolean,

  onServiceEnabled: (service: string, () => void) => void,
  guardService: (service: string, () => void) => void,
}

export const DataPrivacyConsumer = DataPrivacyContext.Consumer;

export class DataPrivacyProvider extends React.Component {
  state: DataPrivacyState;
  serviceEnabledHandler: { [string]: [() => void] } = {};

  constructor() {
    super();
    this.registerService = this.registerService.bind(this);
    this.acceptAll = this.acceptAll.bind(this);
    this.showSettings = this.showSettings.bind(this);
    this.isCategoryEnabled = this.isCategoryEnabled.bind(this);
    this.isServiceEnabled = this.isServiceEnabled.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.onServiceEnabled = this.onServiceEnabled.bind(this);
    this.guardService = this.guardService.bind(this);
    this.state = {
      showConsentNotification: false,
      settingsVisible: false,
      settings: defaultSettings,
      services: {}
    };
  }

  componentWillMount() {
    this.loadSettings();
  }

  // Method to update state
  acceptAll(callback?: (newSettings: DataPrivacySettings) => {}) {
    const oldState = this.state;
    this.setState({
      settingsVisible: false,
      showConsentNotification: false,
      settings: {
        userConfirmed: true,
        categoriesEnabled: {
          tracking: true,
          marketing: true,
          personalization: true,
          functional: true
        },
        servicesEnabled: {}
      }
    }, () => {
      this.saveSettings();
      this.processSettingChanges(oldState, this.state);
      if (callback) {
        callback(this.state.settings);
      }
    });
  }

  registerService(service: ServiceDescription, callback?: () => void) {
    this.setState((oldState) => {
      return {
        ...oldState,
        services: {
          ...oldState.services,
          [service.key]: service
        }
      };
    }, () => {
      if (callback) {
        callback();
      }
    });
  }

  showSettings(value?: boolean) {
    this.setState({settingsVisible: value !== undefined ? value : true});
  }
  

  isCategoryEnabled(category: string, state?: DataPrivacyState) {
    if (!state) {
      state = this.state;
    }
    return state.settings.categoriesEnabled[category] || false;
  }

  isServiceEnabled(service: string, state?: DataPrivacyState) {
    if (!state) {
      state = this.state;
    }
    if (state.services[service] === undefined) {
      console.warn('Service '+service+' not registered. Skipping.');
      return false;
    }
    if (state.settings.servicesEnabled[service] !== undefined) {
      return state.settings.servicesEnabled[service];
    }
    const serviceCategory = state.services[service].category;
    return this.isCategoryEnabled(serviceCategory, state);
  }

  updateSettings(newSettings: DataPrivacySettings, closeSettings?: boolean, callback?: (newSettings: DataPrivacySettings) => {}) {
    const oldState = this.state;
    this.setState({
      settingsVisible: (closeSettings === false),
      showConsentNotification: false,
      settings: {
        ...newSettings,
        userConfirmed: true
      }
    }, () => {
      this.saveSettings();
      this.processSettingChanges(oldState, this.state);
      if (callback) {
        callback(this.state.settings);
      }
    });
  }
  
  processSettingChanges(oldState: DataPrivacyState, newState: DataPrivacyState) {
    // notify handlers of updated services
      Object.values(this.state.services).forEach((service: ServiceDescription) => {
        if (this.serviceEnabledHandler[service.key]) {
          if (this.isServiceEnabled(service.key, newState) && !this.isServiceEnabled(service.key, oldState)) {
            this.serviceEnabledHandler[service.key].forEach(handler => handler());
          }  
        }
      })
  }

  loadSettings() {
    const settingsItem = localStorage.getItem('techAtomDataPrivacy');
    let settings = defaultSettings;
    try {
      settings = settingsItem ? JSON.parse(settingsItem) : defaultSettings;
    } catch (e) {
      console.error('invalid settings JSON. Resetting');
    }
    this.setState({
      settings: {
        ...defaultSettings,
        ...settings
      },
      showConsentNotification: !settings.userConfirmed
    });
  }

  saveSettings() {
    const settingsString = JSON.stringify(this.state.settings);
    localStorage.setItem('techAtomDataPrivacy', settingsString);
  }

  onServiceEnabled(service: string, handler: () => void) {
    if (this.serviceEnabledHandler[service] === undefined) {
      this.serviceEnabledHandler[service] = [];
    }
    this.serviceEnabledHandler[service].push(handler);
    if (this.isServiceEnabled(service)) {
      handler();
    }
  }

  guardService(service: string, callback: () => void) {
    if (this.isServiceEnabled(service)) {
      callback();
    }
  }

  render() {
    const {children} = this.props;
    const {settings, showConsentNotification, settingsVisible} = this.state;

    const contextValue: DataPrivacyContextProps = {
      settings,
      showConsentNotification,
      settingsVisible,
      registerService: this.registerService,
      acceptAll: this.acceptAll,
      showSettings: this.showSettings,
      updateSettings: this.updateSettings,
      onServiceEnabled: this.onServiceEnabled,
      guardService: this.guardService
    };

    return (
        <DataPrivacyContext.Provider
            value={contextValue}
        >
          {children}
        </DataPrivacyContext.Provider>
    );
  }
}

export default DataPrivacyContext;
/*
export default function DataPrivacyProvider(props) {
  const showConsentNotification = true;
  return (<React.Fragment>
    {props.children}
    {showConsentNotification && props.consentNotification}
  </React.Fragment>);
};
*/