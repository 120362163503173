import * as React from "react";
import DefaultPageContainer from "../components/DefaultPageContainer";
import DefaultPageContent from "../components/DefaultPageContent";
import useScrollToTop from "../modules/helper/useScrollToTop";
import Helmet from "react-helmet";

export default function Impressum() {
  useScrollToTop();

  return (
    <DefaultPageContainer>
      <Helmet>
        <title>
          Impressum | TechAtom – Die Programmiersprache zum Anfassen
        </title>
        <meta name="description" content="" />
        <meta
          property="og:title"
          content="Impressum | TechAtom – Die Programmiersprache zum Anfassen"
        />
        <meta property="og:description" content="" />
      </Helmet>
      <DefaultPageContent>
        <h1>Impressum</h1>
        <p>
          TechAtom ist ein Produkt der{" "}
          <a href="https://www.taprolabs.de" target="_blank">
            tapro labs GmbH
          </a>
        </p>

        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
          tapro labs GmbH <br />
          Kisdorfer Str. 14
          <br />
          24641 Sievershütten
        </p>
        <p>
          Handelsregister: HRB 161523
          <br />
          Registergericht: Hamburg
        </p>
        <p>
          Vertreten durch:
          <br />
          Tobias Kleine
        </p>

        <h2>Kontakt</h2>
        <p>
          E-Mail:{" "}
          <a href="mailto:kontakt@taprolabs.de">
            kontakt@taprolabs.de
          </a>
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a
          Umsatzsteuergesetz: <br />
          DE328679907
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
          >
            https://ec.europa.eu/consumers/odr
          </a>
          . Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>
          Verbraucherstreitbeilegung / Universalschlichtungsstelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an
          Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <h2>Haftung für Inhalte</h2>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
          Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
          verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach
          Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen
          bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
          jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
          Rechtsverletzung möglich. Bei Bekanntwerden von
          entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>

        <h2>Haftung für Links</h2>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter,
          auf deren Inhalte wir keinen Einfluss haben. Deshalb können
          wir für diese fremden Inhalte auch keine Gewähr übernehmen.
          Für die Inhalte der verlinkten Seiten ist stets der
          jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
          Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
          auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
          waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist
          jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
          nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Links umgehend entfernen.
        </p>

        <h2>Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke
          auf diesen Seiten unterliegen dem deutschen Urheberrecht.
          Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
          der Verwertung außerhalb der Grenzen des Urheberrechtes
          bedürfen der schriftlichen Zustimmung des jeweiligen Autors
          bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
          für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber
          erstellt wurden, werden die Urheberrechte Dritter beachtet.
          Insbesondere werden Inhalte Dritter als solche
          gekennzeichnet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, bitten wir um
          einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend
          entfernen.
        </p>

        <h2>Bildnachweise</h2>
        <p>
          <ul>
            <li>
              3D-Druck-Icon (bearbeitet): Icon erstellt von{" "}
              <a
                href="https://www.flaticon.com/de/autoren/xnimrodx"
                target="_blank"
              >
                xnimrods
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/de/" target="_blank">
                www.flaticon.com
              </a>
            </li>
            <li>
              Schraubenschlüssel-Icon (bearbeitet): Icon erstellt von{" "}
              <a
                href="https://www.flaticon.com/de/autoren/freepik"
                target="_blank"
              >
                Freepik
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/de/" target="_blank">
                www.flaticon.com
              </a>
            </li>
            <li>
              Hand-Icon (bearbeitet):{" "}
              <a
                href="https://icons8.com/icon/24361/ok-hand"
                target="_blank"
              >
                Icons8
              </a>
            </li>
            <li>
              Puzzle-Icon:{" "}
              <a
                href="https://www.svgrepo.com/svg/123582/puzzle-piece"
                target="_blank"
              >
                SVG Repo
              </a>
              ,{" "}
              <a
                href="https://www.svgrepo.com/page/licensing"
                target="_blank"
              >
                Lizenz
              </a>
            </li>
            <li>
              Rohstoff-Icon: Sustainability by P Thanga Vignesh from{" "}
              <a
                href="https://thenounproject.com/term/sustainability/260263/"
                target="_blank"
              >
                the Noun Project
              </a>
            </li>
            <li>
              Background patterns from{" "}
              <a
                href="https://www.toptal.com/designers/subtlepatterns/"
                target="_blank"
              >
                Toptal Subtle Patterns
              </a>
              : squared_metal, notebook
            </li>
            <li>
              Vector network background abstract polygon triangle:{" "}
              <a href="http://www.freepik.com" target="_blank">
                Designed by ikatod / Freepik
              </a>
            </li>
            <li>
              Tablet clipart:{" "}
              <a
                href="https://creazilla.com/nodes/23775-tablet-clipart"
                target="_blank"
              >
                Designed by franklevel
              </a>
              ,{" "}
              <a href="https://openclipart.org/" target="_blank">
                OpenClipart
              </a>
            </li>
          </ul>
        </p>
      </DefaultPageContent>
    </DefaultPageContainer>
  );
}
