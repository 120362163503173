import * as React from 'react';
import type {GoogleAnalyticsContextProps} from './GoogleAnalyticsContext';
import {useContext} from 'react';
import GoogleAnalyticsContext from './GoogleAnalyticsContext';
import {HashLink} from 'react-router-hash-link';
import ParallaxHelper from '../helper/ParallaxHelper';

type Props = {
  to: string,
  onClick: ?() => void,
  children: React.ReactFragment,
  trackingData: {
    section: string,
    label?: string,
  },
}

export default function TrackedHashLink(props: Props) {
  const googleAnalytics: GoogleAnalyticsContextProps = useContext(GoogleAnalyticsContext);

  function onClick(e) {
    googleAnalytics.event('link_click', {
      ...googleAnalytics.identifierParams(
          props.trackingData.section,
          props.to,
          props.trackingData.label
      ),
      ...props.trackingData,
    });
    if (props.onClick) {
      props.onClick(e);
    }
  }
  return (
      <HashLink onClick={onClick} to={props.to}>{props.children}</HashLink>
  )
}